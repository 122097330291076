import { useDispatch, useSelector } from "react-redux"

import { actions } from "./store/slice"

const useItemDrawer = () => {
  const dispatch = useDispatch()

  const {
    opened,
    itemId: currentItemId,
    items: currentItems
  } = useSelector((state) => state.supportingMaterials.itemDrawer)

  const open = ({ itemId, items = [] }) => {
    dispatch(actions.open({ itemId, items }))
  }

  const close = () => dispatch(actions.close())

  const setCurrentItems = (newCurrentItems) =>
    open({ itemId: currentItemId, items: newCurrentItems })

  const item = currentItems.find((currentItem) => currentItem.id === currentItemId)

  return { open, close, opened, item, setItems: setCurrentItems }
}

export default useItemDrawer
