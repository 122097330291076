import React from "react"
import { Dropdown } from "react-bootstrap"

import { be } from "src/helpers/document"
import Icon from "src/styles/components/Icon"
import useDocumentPreview from "src/hooks/useDocumentPreview"

const PrintMessages = () => {
  const { close: closeDocumentPreview } = useDocumentPreview()

  const openPrintDialogs = () => {
    be("print-messages-form").removeClass("hidden")
    be("message-selection").removeClass("hidden")
    $(".message-block").addClass("with-selector")
    $(
      '[data-behavior="show-new-message-form"], [data-behavior="new-message-form-wrapper"]'
    ).each(function hidePrintButtons() {
      if ($(this).is(":visible")) {
        $(this).addClass("hidden-for-print-mode")
        $(this).hide()
      }
    })
  }

  const handlePrintDialogOpen = () => {
    closeDocumentPreview()
    openPrintDialogs()
  }

  return (
    <Dropdown.Item onClick={handlePrintDialogOpen}>
      <div className="dropdown-icon">
        <Icon type="print" />
      </div>
      Print messages
    </Dropdown.Item>
  )
}

export default PrintMessages
