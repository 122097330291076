import React, { useEffect } from "react"
import { shape, number, string } from "prop-types"

import useCurrentEntity from "src/hooks/useCurrentEntity"
import { FocusableItemType } from "src/features/UniversalAi/constants"
import useDocumentPreview from "src/features/SupportingMaterialsVault/DocumentPreview/useDocumentPreview"

import ItemsList from "./ItemsList"
import { Container } from "./styles"
import DocumentPreview from "./DocumentPreview"
import Header from "./Header"
import useLoadingDocuments from "./useLoadingDocuments"
import useVaultItems from "./useVaultItems"
import useItemDrawer from "./useItemDrawer"

const SupportingMaterialsVault = ({ vault }) => {
  const { setCurrentEntity, clearCurrentEntity } = useCurrentEntity()
  const { opened: documentPreviewOpened, open: openDocumentPreview } =
    useDocumentPreview()
  const { resetLoadingDocuments } = useLoadingDocuments()
  const { opened, setItems } = useItemDrawer()
  const { vaultItems, loading } = useVaultItems({
    vaultId: vault.id,
    onRefetch: ({ vaultItems: newVaultItems }) => {
      if (opened) setItems(newVaultItems)
      resetLoadingDocuments()
    }
  })

  useEffect(() => {
    const documentId = new URLSearchParams(window.location.search).get("document_id")

    if (documentId) openDocumentPreview({ documentId })
  }, [])

  useEffect(() => {
    if (documentPreviewOpened) return

    setCurrentEntity({
      id: vault.id,
      type: FocusableItemType.SupportingMaterialsVault
    })

    return () => clearCurrentEntity()
  }, [documentPreviewOpened])

  return (
    <>
      <Header vaultName={vault.name} />
      <Container>
        <ItemsList vaultItems={vaultItems} loading={loading} />
      </Container>

      {documentPreviewOpened && <DocumentPreview />}
    </>
  )
}

SupportingMaterialsVault.propTypes = {
  vault: shape({ id: number.isRequired, name: string.isRequired })
}

export default SupportingMaterialsVault
