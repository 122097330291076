import { useDispatch, useSelector } from "react-redux"

import { actions } from "./store/slice"

const useLoadingDocuments = () => {
  const dispatch = useDispatch()
  const { documents } = useSelector((state) => state.supportingMaterials.loadingDocuments)

  const setItemLoadingDocuments = (itemId) => (itemDocuments) =>
    dispatch(actions.setItemDocuments({ itemId, documents: itemDocuments }))

  const itemLoadingDocuments = (itemId) => documents[itemId] || []

  const resetLoadingDocuments = () => dispatch(actions.resetDocuments())

  return {
    itemLoadingDocuments,
    resetLoadingDocuments,
    setItemLoadingDocuments
  }
}

export default useLoadingDocuments
