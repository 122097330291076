import React from "react"

import { ItemStaleAlert } from "src/features/SupportingMaterialsVault/ItemStaleAlert"
import AddDocument from "src/features/SupportingMaterialsVault/AddDocument"
import useItemDrawer from "src/features/SupportingMaterialsVault/useItemDrawer"
import DocumentCard from "src/features/SupportingMaterialsVault/DrawerDocumentCard"
import useLoadingDocuments from "src/features/SupportingMaterialsVault/useLoadingDocuments"
import { SubTitle } from "src/features/SupportingMaterialsVault/drawerStyles"

import InformationSection from "./InformationSection"

const CurrentTab = () => {
  const { item } = useItemDrawer()
  const { itemLoadingDocuments, setItemLoadingDocuments } = useLoadingDocuments()

  return (
    <>
      {item.stale && <ItemStaleAlert text={item.staleNotificationText} />}

      <SubTitle>Current Documents</SubTitle>

      {item.vaultDocuments.map((document) => (
        <DocumentCard key={document.id} document={document} />
      ))}

      <AddDocument
        vaultItemId={item.id}
        vaultItemName={item.name}
        loadingDocuments={itemLoadingDocuments(item.id)}
        setLoadingDocuments={setItemLoadingDocuments(item.id)}
      />

      {item.whatRequired && (
        <InformationSection title="What's required" text={item.whatRequired} />
      )}

      {item.whyRequired && (
        <InformationSection title="Why's required" text={item.whyRequired} />
      )}
    </>
  )
}

export default CurrentTab
