import React, { useState, useEffect } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { NetworkStatus, useQuery } from "@apollo/client"

import { CHANNELS } from "src/constants/ably"
import ContentLoader from "src/styles/components/ContentLoader"
import useRealtimeUpdates from "src/hooks/useRealtimeUpdates"
import useItemDrawer from "src/features/SupportingMaterialsVault/useItemDrawer"
import { SubTitle } from "src/features/SupportingMaterialsVault/drawerStyles"
import DocumentCard from "src/features/SupportingMaterialsVault/DrawerDocumentCard"

import { Text } from "./styles"
import archivedVaultDocumentsQuery from "./archivedVaultDocuments.gql"

const PER_PAGE_LIMIT = 10

const HistoricalTab = () => {
  const { item } = useItemDrawer()
  const [hasNextPage, setHasNextPage] = useState(false)

  const { data, loading, refetch, networkStatus, fetchMore } = useQuery(
    archivedVaultDocumentsQuery,
    {
      variables: {
        vaultItemId: item.id,
        offset: 0,
        limit: PER_PAGE_LIMIT
      },
      notifyOnNetworkStatusChange: true
    }
  )

  const archivedDocuments = data?.supportingMaterialsArchivedVaultDocuments || []

  useEffect(() => {
    if (archivedDocuments.length === PER_PAGE_LIMIT) setHasNextPage(true)
  }, [archivedDocuments.length])

  const updateQuery = (previousResult, { fetchMoreResult }) => {
    if (!fetchMoreResult) {
      return previousResult
    }

    const previousDocuments = previousResult.supportingMaterialsArchivedVaultDocuments
    const fetchMoreDocuments = fetchMoreResult.supportingMaterialsArchivedVaultDocuments

    if (fetchMoreDocuments.length < PER_PAGE_LIMIT) setHasNextPage(false)

    return {
      supportingMaterialsArchivedVaultDocuments: [
        ...previousDocuments,
        ...fetchMoreDocuments
      ]
    }
  }

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () =>
      fetchMore({
        updateQuery,
        variables: {
          offset: archivedDocuments.length
        }
      })
  })

  useRealtimeUpdates(CHANNELS.supportingMaterials, (publisherData) => {
    if (
      publisherData.vaultItemId === item.id &&
      (publisherData.action === "delete" ||
        publisherData.action === "archive" ||
        publisherData.action === "unarchive")
    ) {
      refetch()
    }
  })

  const documentsLoading = loading && networkStatus !== NetworkStatus.refetch

  return (
    <>
      <SubTitle>Historical documents</SubTitle>

      {!archivedDocuments.length && !documentsLoading && (
        <Text>No historical documents</Text>
      )}

      {archivedDocuments.map((document) => (
        <DocumentCard key={document.id} document={document} />
      ))}

      <div ref={infiniteRef} />

      {documentsLoading && <ContentLoader />}
    </>
  )
}

export default HistoricalTab
