import React from "react"
import { string, shape } from "prop-types"

import { formatDate } from "src/helpers/datetime"
import { SubTitle } from "src/features/SupportingMaterialsVault/drawerStyles"

import { Name, Container, Value, FieldContainer, FieldsContainer } from "./styles"

const FileDetails = ({ document }) => {
  return (
    <Container>
      <SubTitle>File details</SubTitle>

      <FieldsContainer>
        <FieldContainer>
          <Name>Last modified</Name>
          <Value>{formatDate(document.contentModifiedAt)}</Value>
        </FieldContainer>

        <FieldContainer>
          <Name>Created</Name>
          <Value>{formatDate(document.contentCreatedAt)}</Value>
        </FieldContainer>

        <FieldContainer>
          <Name>Uploaded</Name>
          <Value>{formatDate(document.createdAt)}</Value>
        </FieldContainer>
      </FieldsContainer>
    </Container>
  )
}

FileDetails.propTypes = {
  document: shape({
    contentModifiedAt: string.isRequired,
    contentCreatedAt: string.isRequired,
    createdAt: string.isRequired
  }).isRequired
}

export default FileDetails
