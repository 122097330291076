import React, { useContext } from "react"
import { string } from "prop-types"
import { Spinner } from "react-bootstrap"
import classNames from "classnames"
import { useSelector } from "react-redux"

import MeetingPackOfflineContext from "src/features/meetingPacks/MeetingPack/View/MeetingPackOfflineContext"
import { DOCUMENT_LOADING_STATES } from "src/features/meetingPacks/MeetingPack/constants"
import buildAiButtons from "src/components/pspsdfkitButtons/AiButtons/build"
import { FocusableItemType } from "src/features/UniversalAi/constants"
import { useUniversalAi } from "src/features/UniversalAi"
import {
  getActiveDocument,
  getCurrentUserId,
  getPage
} from "src/features/meetingPacks/MeetingPack/selectors"

import usePSPDFKit from "./usePSPDFKit"
import { DocumentEditorContainer, Loading } from "./styles"

const Document = ({ highlighted }) => {
  const userId = useSelector(getCurrentUserId)
  const document = useSelector(getActiveDocument)
  const page = useSelector(getPage)

  const { isSavedOffline } = useContext(MeetingPackOfflineContext)

  const { summariseAsTextInSidebar, queryInSidebar } = useUniversalAi()

  const summariseDocumentHandler = () =>
    summariseAsTextInSidebar({
      id: document.id,
      type: FocusableItemType.AgendaItemDocument
    })

  const queryDocumentHandler = () =>
    queryInSidebar({
      id: document.id,
      type: FocusableItemType.AgendaItemDocument
    })

  const customToolbarButtons = buildAiButtons({
    summariseDocumentHandler,
    queryDocumentHandler,
    documentContentExtracted: document.contentExtracted
  })

  const { containerRef, loadingState } = usePSPDFKit({
    userId,
    page,
    highlighted,
    documentId: document.id,
    useCache: isSavedOffline,
    container: "#document-preview",
    customToolbarButtons
  })

  return (
    <div>
      {loadingState === DOCUMENT_LOADING_STATES.isLoading && (
        <Loading>
          <Spinner animation="border" variant="secondary" />
        </Loading>
      )}

      <DocumentEditorContainer
        id="document-preview"
        className={classNames("document-container", {
          invisible: loadingState !== DOCUMENT_LOADING_STATES.loaded
        })}
        ref={containerRef}
      />
    </div>
  )
}

Document.propTypes = {
  highlighted: string
}

export default Document
