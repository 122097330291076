import initialState from "./initialState"

const setItemDocuments = (state, action) => {
  const { itemId, documents } = action.payload

  state.documents = {
    ...state.documents,
    [itemId]: documents
  }
}

const resetDocuments = (_state, _action) => initialState

export { setItemDocuments, resetDocuments }
