import { bodyData } from "src/helpers/document"
import { BREAKPOINTS as SIZES } from "src/styles/sizes"

const toolbarItems = [
  { type: "pager", mediaQueries: [`(min-width: 1px)`] },
  { type: "zoom-out", mediaQueries: [`(min-width: ${SIZES.xSmall})`] },
  { type: "zoom-in", mediaQueries: [`(min-width: ${SIZES.xSmall})`] },
  { type: "zoom-mode", mediaQueries: [`(min-width: ${SIZES.xSmall})`] },
  { type: "spacer", mediaQueries: [`(min-width: ${SIZES.xSmall})`] },
  { type: "annotate", mediaQueries: ["(max-width: 1px)"] },
  { type: "ink" },
  { type: "highlighter" },
  { type: "text-highlighter" },
  { type: "ink-eraser" },
  { type: "note", mediaQueries: ["(min-width: 1px)"] }
]

const getBasicConfiguration = () => ({
  toolbarItems,
  licenseKey: window.PSPDFKitKey,
  styleSheets: [bodyData("pspdfkitEditorPath")],
  instant: false,
  autoSaveMode: window.PSPDFKit.AutoSaveMode.INTELLIGENT,
  enableAutomaticLinkExtraction: false,
  enableServiceWorkerSupport: true,
  printOptions: {
    mode: window.PSPDFKit.PrintMode.EXPORT_PDF,
    quality: window.PSPDFKit.PrintQuality.HIGH
  },
  theme: window.PSPDFKit.Theme.LIGHT
})

export default getBasicConfiguration
