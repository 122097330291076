import React, { useEffect, useRef } from "react"
import { bool, string, number } from "prop-types"
import * as Sentry from "@sentry/browser"
import { compact } from "lodash"

import { useUniversalAi } from "src/features/UniversalAi"
import buildAiButtons from "src/components/pspsdfkitButtons/AiButtons/build"
import { FocusableItemType } from "src/features/UniversalAi/constants"
import buildThumbnailsButton from "src/components/pspsdfkitButtons/ThumbnailsButton/buildThumbnailsButton"
import { bodyData } from "src/helpers/document"
import buildFullscreenButton from "src/components/pspsdfkitButtons/FullscreenButton/buildFullscreenButton"

import { Container } from "./styles"

const toolbarItems = [
  { type: "pager" },
  { type: "zoom-out" },
  { type: "zoom-in" },
  { type: "zoom-mode" },
  { type: "spacer" }
]

const DocumentViewer = ({ documentId, pspdfkitId, jwt, contentExtracted }) => {
  const containerRef = useRef(null)
  const { summariseAsTextInSidebar, queryInSidebar } = useUniversalAi()

  const summariseDocumentHandler = () =>
    summariseAsTextInSidebar({
      id: documentId,
      type: FocusableItemType.SupportingMaterialsVaultDocument
    })

  const queryDocumentHandler = () =>
    queryInSidebar({
      id: documentId,
      type: FocusableItemType.SupportingMaterialsVaultDocument
    })

  const customToolbarButtons = buildAiButtons({
    summariseDocumentHandler,
    queryDocumentHandler,
    documentContentExtracted: contentExtracted
  })

  useEffect(() => {
    if (window.PSPDFKit) {
      window.PSPDFKit.load({
        licenseKey: window.PSPDFKitKey,
        serverUrl: window.pspdfkitServerUrl,
        documentId: pspdfkitId,
        toolbarItems,
        layers: [],
        enableAutomaticLinkExtraction: false,
        printOptions: {
          mode: window.PSPDFKit.PrintMode.EXPORT_PDF,
          quality: window.PSPDFKit.PrintQuality.HIGH
        },
        container: "#document-preview",
        editableAnnotationTypes: [],
        styleSheets: [bodyData("pspdfkitEditorPath")],
        authPayload: { jwt },
        instant: false
      })
        .then((instance) => {
          instance.setToolbarItems((items) => {
            return compact([
              ...customToolbarButtons,
              buildThumbnailsButton(instance),
              ...items,
              { type: "search" },
              buildFullscreenButton(instance)
            ])
          })

          return instance
        })
        .then((instance) => {
          const page = new URLSearchParams(window.location.search).get("page")
          if (page) {
            instance.setViewState((state) =>
              state.set("currentPageIndex", parseInt(page) - 1)
            )
          }

          return instance
        })
        .catch((e) => {
          Sentry.captureException(e)
        })

      return () => {
        if (document.querySelector("#document-preview"))
          window.PSPDFKit.unload("#document-preview")
      }
    }
  }, [pspdfkitId, !!window.PSPDFKit])

  return <Container id="document-preview" ref={containerRef} />
}

DocumentViewer.propTypes = {
  documentId: number.isRequired,
  pspdfkitId: string.isRequired,
  jwt: string.isRequired,
  contentExtracted: bool.isRequired
}

export default DocumentViewer
