import React, { useState } from "react"
import { string, number, arrayOf, shape } from "prop-types"
import { useMutation } from "@apollo/client"

import alertIcon from "images/alert-triangle.svg"
import Select from "src/styles/components/Select"

import {
  AlertContainer,
  Icon,
  Text,
  PrimaryButton,
  SecondaryButton,
  ButtonsContainer,
  SelectContainer,
  selectStyles
} from "./styles"
import updateItemMutation from "./updateItem.gql"
import deleteDocumentMutation from "./deleteDocument.gql"

const IncorrectItem = ({ documentId, recommendedVaultItemId, items }) => {
  const [updateItem] = useMutation(updateItemMutation)
  const [deleteDocument] = useMutation(deleteDocumentMutation, {
    variables: { vaultDocumentId: documentId }
  })

  const itemOptions = items.map((item) => ({ value: item.id, label: item.name }))

  const [selectedItemOption, setSelectedItemOption] = useState(
    recommendedVaultItemId
      ? itemOptions.find((itemOption) => itemOption.value === recommendedVaultItemId)
      : null
  )

  const handleConfirm = () =>
    updateItem({
      variables: { vaultDocumentId: documentId, vaultItemId: selectedItemOption.value }
    })

  return (
    <>
      <AlertContainer>
        <Icon src={alertIcon} />
        <Text>
          <div>
            It looks like this document belongs to a different item. Please confirm.
          </div>
        </Text>
      </AlertContainer>

      <SelectContainer>
        <Select
          placeholder="Select item"
          onChange={(itemOption) => setSelectedItemOption(itemOption)}
          options={itemOptions}
          styles={selectStyles}
          value={selectedItemOption}
        />
      </SelectContainer>

      <ButtonsContainer>
        <SecondaryButton onClick={deleteDocument}>Cancel upload</SecondaryButton>
        <PrimaryButton onClick={handleConfirm} disabled={!selectedItemOption}>
          Confirm item
        </PrimaryButton>
      </ButtonsContainer>
    </>
  )
}

IncorrectItem.propTypes = {
  documentId: number.isRequired,
  recommendedVaultItemId: number.isRequired,
  items: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired
    })
  )
}

export default IncorrectItem
