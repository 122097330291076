import React from "react"
import { number, string } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"
import useLoadingDocuments from "src/features/SupportingMaterialsVault/useLoadingDocuments"

import { Button } from "./styles"
import UploadModal from "./UploadModal"
import LoadingDocument from "./LoadingDocument"

const AddDocument = ({ vaultItemId, vaultItemName }) => {
  const {
    isOpened: isUploadModalOpened,
    open: openUploadModal,
    close: closeUploadModal
  } = useIsOpened()
  const { itemLoadingDocuments, setItemLoadingDocuments } = useLoadingDocuments()
  const loadingDocuments = itemLoadingDocuments(vaultItemId)

  return (
    <>
      {!!loadingDocuments.length &&
        loadingDocuments.map((loadingDocument) => (
          <LoadingDocument key={loadingDocument.name} filename={loadingDocument.name} />
        ))}

      <Button onClick={openUploadModal}>
        <strong>+</strong> Add document
      </Button>

      {isUploadModalOpened && (
        <UploadModal
          close={closeUploadModal}
          vaultItemId={vaultItemId}
          vaultItemName={vaultItemName}
          addDocuments={(documents) => setItemLoadingDocuments(vaultItemId)(documents)}
        />
      )}
    </>
  )
}

AddDocument.propTypes = {
  vaultItemId: number.isRequired,
  vaultItemName: string.isRequired
}

export default AddDocument
