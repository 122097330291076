import initialState from "./initialState"

const open = (state, action) => {
  const { itemId, items } = action.payload

  state.opened = true
  state.itemId = itemId
  state.items = items
}

const close = (_state, _action) => initialState

export { open, close }
