import React from "react"
import { Accordion } from "react-bootstrap"
import { shape, number, string, bool, arrayOf, func } from "prop-types"

import AddDocument from "src/features/SupportingMaterialsVault/AddDocument"

import Header from "./Header"
import { DocumentsList, ItemCardContainer } from "./styles"
import DocumentCard from "./DocumentCard"

const ItemCard = ({ vaultItem, vaultItemOptions, openItemDrawer }) => {
  return (
    <Accordion defaultActiveKey="0">
      <ItemCardContainer
        key={vaultItem.id}
        className="mt-3"
        data-testid="item-card"
        data-id={vaultItem.id}
      >
        <Header
          itemName={vaultItem.name}
          documentsCount={vaultItem.vaultDocuments.length}
          stale={vaultItem.stale}
          staleNotificationText={vaultItem.staleNotificationText}
          eventKey="0"
          onItemTitleClick={openItemDrawer}
        />

        <Accordion.Collapse eventKey="0">
          <DocumentsList>
            {vaultItem.vaultDocuments.map((vaultDocument) => (
              <DocumentCard
                key={vaultDocument.id}
                document={vaultDocument}
                staleNotificationText={vaultItem.staleNotificationText}
                items={vaultItemOptions}
              />
            ))}

            <AddDocument vaultItemId={vaultItem.id} vaultItemName={vaultItem.name} />
          </DocumentsList>
        </Accordion.Collapse>
      </ItemCardContainer>
    </Accordion>
  )
}

ItemCard.propTypes = {
  vaultItem: shape({
    id: number.isRequired,
    name: string.isRequired,
    stale: bool.isRequired,
    vaultDocuments: arrayOf(
      shape({
        id: number.isRequired,
        filename: string.isRequired,
        pspdfkitId: string.isRequired
      })
    )
  }).isRequired,
  openItemDrawer: func.isRequired,
  vaultItemOptions: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired
    })
  )
}

export default ItemCard
