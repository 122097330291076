import React from "react"
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types"

import FileName from "src/features/SupportingMaterialsVault/DocumentFileName"
import Actions from "src/features/SupportingMaterialsVault/DocumentActions"
import Fields from "src/features/SupportingMaterialsVault/DocumentFields"
import {
  DocumentCardContainer,
  DocumentFieldsContainer,
  DocumentHeader
} from "src/features/SupportingMaterialsVault/ItemDrawer/styles"

const DrawerDocumentCard = ({ document }) => {
  return (
    <DocumentCardContainer data-testid="document-card" data-id={document.id}>
      <DocumentHeader>
        <FileName document={document} />
        <Actions documentId={document.id} documentArchived={document.archived} />
      </DocumentHeader>
      <DocumentFieldsContainer>
        <Fields fieldValues={document.itemFieldValues} />
      </DocumentFieldsContainer>
    </DocumentCardContainer>
  )
}

DrawerDocumentCard.propTypes = {
  document: shape({
    id: number.isRequired,
    filename: string.isRequired,
    pspdfkitId: string.isRequired,
    itemFieldValues: arrayOf(
      shape({
        name: string.isRequired,
        value: oneOfType([string, number]),
        complete: bool.isRequired
      })
    )
  })
}

export default DrawerDocumentCard
