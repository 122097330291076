import React, { useCallback, useContext, useEffect, useState } from "react"
import { arrayOf, bool, number, shape, string, func } from "prop-types"
import { Collapse } from "react-bootstrap"

import SidebarContext from "src/features/Sidebar/SidebarContext"
import {
  getCurrentMainSchemeId,
  getCurrentMeetingPackId,
  getCurrentSupportingMaterialsVaultId
} from "src/helpers/document"
import { isAccountManagePage, isAddMeetingPackPage } from "src/helpers/routes"
import Icon from "src/styles/components/Icon"

import { addBadgeToTheScheme } from "./helpers"
import MeetingPack from "./MeetingPack"
import AccountName from "./AccountName"
import { AddMeetingPackLink, Divider, SubSectionTitle } from "./styles"
import MenuItem from "./MenuItem"

const hiddenAccountSectionKey = (id) => {
  return `hidden-account-sidebar-section-${id}`
}

const Account = ({
  account,
  isStarAvailable,
  focusAccount,
  setFocusAccountAfterUpdate
}) => {
  const currentMeetingPackId = getCurrentMeetingPackId()
  const currentMainSchemeId = getCurrentMainSchemeId()
  const currentSupportingMaterialsVaultId = getCurrentSupportingMaterialsVaultId()
  const current =
    isAccountManagePage(window.location.pathname, account.id) ||
    isAddMeetingPackPage(window.location.pathname, window.location.search, account.id)

  const { stats, syncStats } = useContext(SidebarContext)

  const meetingPacks = account?.meetingPacks
  const schemes = account?.schemes?.map((scheme) => addBadgeToTheScheme(scheme, stats))
  const supportingMaterialsVault = account?.supportingMaterialsVault

  const hasMeetings = !!meetingPacks?.length
  const hasSchemes = !!schemes?.length
  const hasSupportingMaterialsVault = !!supportingMaterialsVault

  const hasUpdatedMeetings = meetingPacks?.some((meetingPack) => !!meetingPack?.unread)
  const hasUpdatesInSchemes = schemes.some(({ badge }) => Boolean(badge))

  const hasUpdates = hasUpdatedMeetings || hasUpdatesInSchemes

  const [isAccountOpened, setIsAccountOpened] = useState(
    !localStorage.getItem(hiddenAccountSectionKey(account.id)) || hasUpdates
  )

  useEffect(() => {
    if (hasUpdates) {
      setIsAccountOpened(true)
    }
  }, [hasUpdates])

  const toggleAccountOpened = useCallback(
    (e) => {
      // don't collapse list when follow the link
      if (e.target?.href) {
        return
      }

      const willAccountBeOpened = !isAccountOpened

      if (willAccountBeOpened) {
        localStorage.removeItem(hiddenAccountSectionKey(account.id))
      } else {
        localStorage.setItem(hiddenAccountSectionKey(account.id), "true")
      }

      setIsAccountOpened(willAccountBeOpened)
    },
    [isAccountOpened]
  )

  return (
    <div
      className="sidebar-subsection"
      data-testid="account-sidebar-section"
      data-id={account.id}
    >
      <Divider />
      <AccountName
        account={account}
        isAccountOpened={isAccountOpened}
        isStarAvailable={isStarAvailable}
        current={current}
        onClick={toggleAccountOpened}
        focusAccount={focusAccount}
        setFocusAccountAfterUpdate={setFocusAccountAfterUpdate}
      />

      <Collapse in={isAccountOpened}>
        <div>
          {(hasMeetings || account.canCreateMeetingPack) && (
            <>
              <SubSectionTitle className="sidebar-subsection-title">
                Upcoming Meetings
                {account.canCreateMeetingPack && (
                  <AddMeetingPackLink href={account.newMeetingPath}>
                    <Icon type="add" /> Add
                  </AddMeetingPackLink>
                )}
              </SubSectionTitle>
              {meetingPacks.map((meetingPack) => (
                <MeetingPack
                  key={meetingPack.id}
                  current={meetingPack.id === currentMeetingPackId}
                  syncStats={syncStats[meetingPack.id]}
                  {...meetingPack}
                />
              ))}
            </>
          )}

          {hasSchemes && (
            <>
              <SubSectionTitle className="sidebar-subsection-title with-padding">
                Collaboration spaces
              </SubSectionTitle>
              {schemes.map((scheme) => (
                <MenuItem
                  key={scheme.id}
                  id={scheme.id}
                  current={scheme.id === currentMainSchemeId}
                  badge={scheme.badge}
                  name={scheme.name}
                  path={scheme.path}
                />
              ))}
            </>
          )}

          {hasSupportingMaterialsVault && (
            <>
              <SubSectionTitle className="sidebar-subsection-title with-padding">
                Supporting materials
              </SubSectionTitle>
              <MenuItem
                id={supportingMaterialsVault.id}
                current={
                  supportingMaterialsVault.id === currentSupportingMaterialsVaultId
                }
                name={supportingMaterialsVault.name}
                path={supportingMaterialsVault.path}
              />
            </>
          )}
        </div>
      </Collapse>
    </div>
  )
}

Account.propTypes = {
  account: shape({
    id: number.isRequired,
    name: string.isRequired,
    newMeetingPath: string.isRequired,
    managePath: string.isRequired,
    canManage: bool.isRequired,
    meetingPacks: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
        path: string.isRequired
      })
    ),
    schemes: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
        path: string.isRequired
      })
    )
  }),
  isStarAvailable: bool.isRequired,
  focusAccount: bool,
  setFocusAccountAfterUpdate: func.isRequired
}

export default Account
