import { bool } from "prop-types"
import React, { useEffect } from "react"

import DocumentPreview, { Modes, PropTypes } from "src/features/DocumentPreview"
import useDocumentPreview from "src/hooks/useDocumentPreview"
import { useUniversalAi } from "src/features/UniversalAi"
import { FocusableItemType } from "src/features/UniversalAi/constants"
import { buildOpenaiButtons } from "src/helpers/box"
import useCurrentEntity from "src/hooks/useCurrentEntity"

const AttachedDocumentPreview = ({
  mode = Modes.Modal,
  aiButtonsEnabled = false,
  sidebarEnabled = false
}) => {
  const { summariseAsTextInSidebar, queryInSidebar } = useUniversalAi()
  const { setCurrentEntity, clearCurrentEntity } = useCurrentEntity()
  const { opened, document } = useDocumentPreview()

  useEffect(() => {
    if (!opened) return

    setCurrentEntity({ id: document.id, type: "attached_document" })

    return () => clearCurrentEntity()
  }, [opened])

  const toolbarButtonsFactory = (file) => {
    if (!aiButtonsEnabled || !file.contentPresent) {
      return []
    }

    const { summariseButton, askQuestionButton } = buildOpenaiButtons()

    summariseButton.element.addEventListener("click", () =>
      summariseAsTextInSidebar({
        id: file.id,
        type: FocusableItemType.AttachedDocument
      })
    )

    askQuestionButton.element.addEventListener("click", () =>
      queryInSidebar({
        id: file.id,
        type: FocusableItemType.AttachedDocument
      })
    )

    return [summariseButton, askQuestionButton]
  }

  return (
    <DocumentPreview
      mode={mode}
      toolbarButtonsFactory={toolbarButtonsFactory}
      sidebarEnabled={sidebarEnabled}
    />
  )
}

AttachedDocumentPreview.propTypes = {
  ...PropTypes,
  aiButtonsEnabled: bool
}

export default AttachedDocumentPreview
