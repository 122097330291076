import React from "react"
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types"

import { Container } from "src/features/SupportingMaterialsVault/drawerStyles"
import DocumentCard from "src/features/SupportingMaterialsVault/DrawerDocumentCard"

import { Content, DocumentCardContainer } from "./styles"
import FileDetails from "./FileDetails"

const Drawer = ({ document }) => {
  return (
    <Container data-testid="smv-document-sidebar">
      <Content>
        <DocumentCardContainer>
          <DocumentCard document={document} />
        </DocumentCardContainer>

        <FileDetails document={document} />
      </Content>
    </Container>
  )
}

Drawer.propTypes = {
  document: shape({
    id: number.isRequired,
    filename: string.isRequired,
    pspdfkitId: string.isRequired,
    itemFieldValues: arrayOf(
      shape({
        name: string.isRequired,
        value: oneOfType([string, number]),
        complete: bool.isRequired
      })
    )
  })
}

export default Drawer
