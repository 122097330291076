import React, { useState } from "react"
import {
  useFloating,
  autoUpdate,
  useHover,
  useInteractions,
  flip,
  shift,
  useClick,
  useDismiss
} from "@floating-ui/react"
import { func, number, oneOf } from "prop-types"

import { Source as SourceType } from "src/features/UniversalAi/Sidebar/propTypes"
import SourcePreview from "src/features/UniversalAi/Sidebar/ThreadItem/Answer/SourcePreview"
import { FocusableItemType, SourceClickTarget } from "src/features/UniversalAi/constants"
import { possessive } from "src/helpers/string"
import { formatDate } from "src/helpers/datetime"
import { buildTitleWithDeletedLabel } from "src/features/UniversalAi/Sidebar/helpers"
import useMobileScreen from "src/hooks/useMobileScreen"
import SourceReference from "src/features/UniversalAi/Sidebar/ThreadItem/SourceReference"

import { LinkTitleMode, LinkTitleModes } from "../constants"

import { Link, LinkLabel } from "./styles"

const buildMessageTitle = (source) => {
  const formattedCreatedAt = formatDate(source.createdAt, "Do MMM, YYYY")

  return `${possessive(source.author)} message, ${formattedCreatedAt}`
}

const buildMeetingPackTitle = (source) => `Meeting ${source.title}`
const buildDiscussionTitle = (source) => `Discussion ${source.title}`

const buildTitle = (source, mode) => {
  const isMessage = source.sourceType === FocusableItemType.Message
  const isMeetingPack = source.sourceType === FocusableItemType.MeetingPack
  const isDiscussion = source.sourceType === FocusableItemType.Discussion

  if (isMessage) return buildMessageTitle(source)
  if (isMeetingPack) return buildMeetingPackTitle(source)
  if (isDiscussion) return buildDiscussionTitle(source)

  const pageLabel = `Page ${source.page}`
  const titleWithPage = source.page ? `${pageLabel} - ${source.title}` : source.title

  if (mode === LinkTitleMode.Title) {
    return titleWithPage
  }

  if (mode === LinkTitleMode.PageNumber) {
    return pageLabel
  }
}

const Source = ({ index, source, linkTitleMode, onNavigate }) => {
  const isMobile = useMobileScreen()
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip(), shift()]
  })

  const hover = useHover(context, { move: false })
  const click = useClick(context, { enabled: isMobile })
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss])

  const handleLinkClick = () => {
    if (!isMobile) {
      onNavigate(source, SourceClickTarget.SourcesList)
    }
  }

  const handleSourcePreviewNavigate = () => {
    onNavigate(source, SourceClickTarget.PreviewTitle)
  }

  return (
    <>
      <Link
        onClick={handleLinkClick}
        ref={refs.setReference}
        {...getReferenceProps()}
        className={source.sourceDeleted ? "deleted" : null}
      >
        <SourceReference>{index + 1}</SourceReference>

        <LinkLabel>
          {buildTitleWithDeletedLabel(
            buildTitle(source, linkTitleMode),
            source.sourceDeleted
          )}
        </LinkLabel>
      </Link>

      {isOpen && (
        <SourcePreview
          source={source}
          ref={refs.setFloating}
          style={floatingStyles}
          onNavigate={handleSourcePreviewNavigate}
          {...getFloatingProps()}
        />
      )}
    </>
  )
}

Source.propTypes = {
  index: number.isRequired,
  source: SourceType.isRequired,
  linkTitleMode: oneOf(LinkTitleModes).isRequired,
  onNavigate: func.isRequired
}

export default Source
