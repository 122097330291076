import { shape, number, bool } from "prop-types"

import { optionType } from "src/constants/propTypes"

export const filterType = shape({
  publicationStatusFilter: optionType,
  accountFilter: optionType,
  tagFilter: optionType,
  yearFilter: optionType
})

export const filterConstraintsType = shape({
  accountId: number,
  admin: bool,
  past: bool
})
