import { useQuery } from "@apollo/client"

import { getAccountOption, getAccountOptionId } from "src/resources/accounts/helpers"
import { getTagOption, getTagId } from "src/resources/tags/helpers"
import { getPublicationStatus, getYearOption } from "src/resources/meetingPacks/helpers"

import meetingPacksAccounts from "./meetingPacksAccountsQuery.gql"
import meetingPacksTags from "./meetingPacksTagsQuery.gql"
import meetingPacksYears from "./meetingPacksYearsQuery.gql"

const useFiltersQuery = ({ filters, constraints }) => {
  const { accountId, admin, past } = constraints || {}

  const publicationStatus = getPublicationStatus(filters.publicationStatusFilter)

  const accounts = useQuery(meetingPacksAccounts, {
    variables: {
      publicationStatus,
      skip: !!accountId
    }
  })

  const tags = useQuery(meetingPacksTags, {
    variables: { publicationStatus, accountId, admin, past }
  })

  const years = useQuery(meetingPacksYears, {
    variables: {
      publicationStatus,
      tagId: getTagId(filters.tagFilter),
      accountId: accountId || getAccountOptionId(filters.accountFilter),
      admin,
      past
    }
  })

  const accountOptions = (accounts?.data?.meetingPacksAccounts || []).map(
    getAccountOption
  )
  const tagOptions = (tags?.data?.meetingPacksTags || []).map(getTagOption)
  const yearOptions = (years?.data?.meetingPacksYears || []).map(getYearOption)

  return { accountOptions, tagOptions, yearOptions }
}

export default useFiltersQuery
