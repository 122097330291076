import React from "react"
import { arrayOf, bool, func } from "prop-types"

import { FolderItemType, SortingType } from "../types"
import { FolderItemTypes, SortingFields } from "../consts"

import {
  Container,
  Table,
  HeaderIconCell,
  HeaderDateCell,
  HeaderNameCell,
  HeaderModifiedByCell,
  HeaderSizeCell,
  InfoBlock,
  HeaderSelectionCell
} from "./styles"
import LoadingState from "./LoadingState"
import DropZone from "./DropZone"
import SortingToggle from "./SortingToggle"
import Row from "./Row"

const FolderItems = ({
  folderItems,
  selectedFolderItems,
  loading,
  error,
  sorting,
  onNavigate,
  onDropFiles,
  onSelect,
  onDeselect,
  onSelectAll,
  onDeselectAll,
  onPreview,
  onSortingToggle,
  onMove
}) => {
  const isEmpty = !loading && folderItems.length === 0
  const showRows = !loading && !isEmpty && !error
  const areAllSelected =
    folderItems.length > 0 && selectedFolderItems.length === folderItems.length

  const handleNameClick = (folderItem) => {
    if (folderItem.type === FolderItemTypes.Folder) {
      onNavigate(folderItem.boxId)
    } else {
      onPreview(folderItem)
    }
  }

  const isSelected = (folderItem) => {
    return selectedFolderItems.some(
      ({ id, type }) => id === folderItem.id && type === folderItem.type
    )
  }

  const handleSelectionChange = (folderItem, checked) => {
    if (checked) {
      onSelect(folderItem)
    } else {
      onDeselect(folderItem)
    }
  }

  const handleSelectAllChange = (checked) => {
    if (checked) {
      onSelectAll()
    } else {
      onDeselectAll()
    }
  }

  const handleRowClick = (folderItem) => {
    handleSelectionChange(folderItem, !isSelected(folderItem))
  }

  return (
    <DropZone onDrop={onDropFiles}>
      <Container>
        <Table>
          <thead>
            <tr>
              <HeaderSelectionCell>
                <input
                  type="checkbox"
                  checked={areAllSelected}
                  onChange={(e) => handleSelectAllChange(e.target.checked)}
                  disabled={loading || folderItems.length === 0}
                />
              </HeaderSelectionCell>
              <HeaderIconCell />
              <HeaderNameCell>
                <SortingToggle
                  field={SortingFields.Name}
                  sorting={sorting}
                  onToggle={onSortingToggle}
                >
                  Name
                </SortingToggle>
              </HeaderNameCell>
              <HeaderDateCell>
                <SortingToggle
                  field={SortingFields.Uploaded}
                  sorting={sorting}
                  onToggle={onSortingToggle}
                >
                  Uploaded
                </SortingToggle>
              </HeaderDateCell>
              <HeaderDateCell>
                <SortingToggle
                  field={SortingFields.Date}
                  sorting={sorting}
                  onToggle={onSortingToggle}
                >
                  Modified
                </SortingToggle>
              </HeaderDateCell>
              <HeaderModifiedByCell>Updated on Knowa by</HeaderModifiedByCell>
              <HeaderSizeCell>
                <SortingToggle
                  field={SortingFields.Size}
                  sorting={sorting}
                  onToggle={onSortingToggle}
                >
                  Size
                </SortingToggle>
              </HeaderSizeCell>
            </tr>
          </thead>
          <tbody>
            {loading && <LoadingState />}
            {showRows &&
              folderItems.map((folderItem) => (
                <Row
                  key={`${folderItem.type}-${folderItem.id}`}
                  folderItem={folderItem}
                  isSelected={isSelected(folderItem)}
                  onClick={() => handleRowClick(folderItem)}
                  onNameClick={() => handleNameClick(folderItem)}
                  onSelectionChange={(checked) =>
                    handleSelectionChange(folderItem, checked)
                  }
                  onMove={onMove}
                />
              ))}
          </tbody>
        </Table>
        {!error && isEmpty && (
          <InfoBlock>Drag and drop any files here to upload</InfoBlock>
        )}
        {error && <InfoBlock>Something went wrong, please, try again later</InfoBlock>}
      </Container>
    </DropZone>
  )
}

FolderItems.propTypes = {
  folderItems: arrayOf(FolderItemType).isRequired,
  selectedFolderItems: arrayOf(FolderItemType).isRequired,
  loading: bool.isRequired,
  sorting: SortingType.isRequired,
  error: bool.isRequired,
  onNavigate: func.isRequired,
  onDropFiles: func.isRequired,
  onSelect: func.isRequired,
  onDeselect: func.isRequired,
  onSelectAll: func.isRequired,
  onDeselectAll: func.isRequired,
  onPreview: func.isRequired,
  onSortingToggle: func.isRequired,
  onMove: func.isRequired
}

export default FolderItems
