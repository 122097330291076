import React from "react"
import { Dropdown } from "react-bootstrap"
import { useMutation } from "@apollo/client"
import { number, bool } from "prop-types"

import TridotIcon from "src/components/TridotIcon"
import Icon from "src/styles/components/Icon"
import useConfirmationModal from "src/hooks/useConfirmationModal"
import { downloadDocumentUrl } from "src/api/supportingMaterials"
import useDocumentPreview from "src/features/SupportingMaterialsVault/DocumentPreview/useDocumentPreview"

import deleteDocumentMutation from "./deleteDocument.gql"
import archiveDocumentMutation from "./archiveDocument.gql"
import unarchiveDocumentMutation from "./unarchiveDocument.gql"
import { Container } from "./styles"

const Actions = ({ documentId, documentArchived }) => {
  const [deleteVaultDocument] = useMutation(deleteDocumentMutation, {
    variables: { vaultDocumentId: documentId }
  })
  const [archiveDocument] = useMutation(archiveDocumentMutation, {
    variables: { vaultDocumentId: documentId }
  })
  const [unarchiveDocument] = useMutation(unarchiveDocumentMutation, {
    variables: { vaultDocumentId: documentId }
  })
  const { opened: documentPreviewOpened, close: closeDocumentPreview } =
    useDocumentPreview()

  const { modal: deleteDocumentModal, open: openDeleteDocumentModal } =
    useConfirmationModal({
      title: "Confirmation",
      message: "Are you sure you want to delete this document?",
      onConfirm: () => {
        deleteVaultDocument().then(() => {
          if (documentPreviewOpened) closeDocumentPreview()
        })
      }
    })

  const downloadDocument = () => {
    window.location.href = downloadDocumentUrl({ documentId })
  }

  return (
    <Container>
      {deleteDocumentModal}
      <Dropdown>
        <Dropdown.Toggle as={TridotIcon} />

        <Dropdown.Menu alignRight>
          {documentArchived ? (
            <Dropdown.Item onClick={unarchiveDocument}>
              <Icon type="archive-unpack" size="small" /> Mark as current
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={archiveDocument}>
              <Icon type="archive" size="small" /> Archive document
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={downloadDocument}>
            <Icon type="arrow-down-hollow" className="pr-2 pt-1" /> Download
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={openDeleteDocumentModal}>
            <Icon type="trash" className="pr-2" /> Remove document
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  )
}

Actions.propTypes = {
  documentId: number.isRequired,
  documentArchived: bool
}

export default Actions
