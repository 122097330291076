import { oneOf } from "prop-types"
import React from "react"

import { FocusableItemType, FocusableItemTypes } from "src/features/UniversalAi/constants"
import Icon from "src/styles/components/Icon"

export const FOCUSABLE_ITEM_TYPE_TO_ICON = {
  [FocusableItemType.AttachedDocument]: "document",
  [FocusableItemType.Message]: "message",
  [FocusableItemType.Discussion]: "chats",
  [FocusableItemType.Group]: "user-group",
  [FocusableItemType.JointScheme]: "connected",
  [FocusableItemType.PrivateScheme]: "lock",
  [FocusableItemType.Account]: "earth",
  [FocusableItemType.MeetingPack]: "list-numbered",
  [FocusableItemType.AgendaItem]: "list-numbered",
  [FocusableItemType.Transcript]: "list-numbered",
  [FocusableItemType.AssignedTranscriptAgendaItem]: "list-numbered",
  [FocusableItemType.AgendaItemDocument]: "document",
  [FocusableItemType.ContentCloudFile]: "document",
  [FocusableItemType.AppsContent]: "arrow-right-squared",
  [FocusableItemType.SupportingMaterialsVault]: "document",
  [FocusableItemType.SupportingMaterialsVaultDocument]: "document"
}

const FocusableItemIcon = ({ itemType, className }) => {
  return <Icon className={className} type={FOCUSABLE_ITEM_TYPE_TO_ICON[itemType]} />
}

FocusableItemIcon.propTypes = {
  itemType: oneOf(FocusableItemTypes).isRequired
}

export default FocusableItemIcon
