import React from "react"
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types"

import FileName from "src/features/SupportingMaterialsVault/DocumentFileName"
import Actions from "src/features/SupportingMaterialsVault/DocumentActions"
import Fields from "src/features/SupportingMaterialsVault/DocumentFields"

import { DocumentCardContainer, DocumentHeader, FieldsContainer } from "./styles"
import { IncorrectItem, isItemIncorrect } from "./IncorrectItem"

const DocumentCard = ({ document, items }) => {
  return (
    <DocumentCardContainer data-testid="document-card" data-id={document.id}>
      <DocumentHeader>
        <FileName document={document} />
        <Actions documentId={document.id} />
      </DocumentHeader>

      {isItemIncorrect(document) ? (
        <IncorrectItem
          documentId={document.id}
          recommendedVaultItemId={document.recommendedVaultItemId}
          items={items}
        />
      ) : (
        <FieldsContainer>
          <Fields fieldValues={document.itemFieldValues} />
        </FieldsContainer>
      )}
    </DocumentCardContainer>
  )
}

DocumentCard.propTypes = {
  document: shape({
    id: number.isRequired,
    filename: string.isRequired,
    pspdfkitId: string.isRequired,
    itemFieldValues: arrayOf(
      shape({
        name: string.isRequired,
        value: oneOfType([string, number]),
        complete: bool.isRequired
      })
    )
  }),
  items: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired
    })
  )
}

export default DocumentCard
