import React from "react"
import { number, shape, string, bool } from "prop-types"

import FileIcon from "src/styles/components/FileIcon"
import { splitFilename } from "src/helpers/string"
import useDocumentPreview from "src/features/SupportingMaterialsVault/DocumentPreview/useDocumentPreview"

import { FileName, Container } from "./styles"

const FileNameContainer = ({ document, withExtensionIcon = true }) => {
  const { filename } = document
  const { extension } = splitFilename(filename)
  const { open } = useDocumentPreview()

  const handleClick = () => {
    if (document.id) open({ documentId: document.id })
  }

  return (
    <Container onClick={handleClick}>
      {withExtensionIcon && (
        <FileIcon
          extension={extension}
          showExtension={false}
          className="extension-icon"
        />
      )}
      <FileName>{filename}</FileName>
    </Container>
  )
}

FileNameContainer.propTypes = {
  document: shape({
    id: number,
    filename: string.isRequired,
    pspdfkitId: string
  }).isRequired,
  withExtensionIcon: bool
}

export default FileNameContainer
