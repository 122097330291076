import React from "react"
import { arrayOf, bool, func, oneOf } from "prop-types"

import ExpandableList from "src/features/UniversalAi/Sidebar/ThreadItem/ExpandableList"
import { Source as SourceType } from "src/features/UniversalAi/Sidebar/propTypes"

import { SourcesContainer } from "./styles"
import Source from "./Source"
import { LinkTitleMode, LinkTitleModes } from "./constants"

const Sources = ({
  sources,
  toggle,
  onSourceNavigate,
  linkTitleMode = LinkTitleMode.Title,
  opened = false
}) => {
  const toggleLabel = sources.length === 1 ? "Source" : "Sources"

  return (
    <ExpandableList
      toggle={toggle}
      opened={opened}
      label={`${sources.length} ${toggleLabel}`}
    >
      <SourcesContainer>
        {sources.map((source, index) => (
          <Source
            index={index}
            key={`${source.sourceType}-${source.sourceId}-${source.page || index}`}
            linkTitleMode={linkTitleMode}
            source={source}
            onNavigate={onSourceNavigate}
          />
        ))}
      </SourcesContainer>
    </ExpandableList>
  )
}

Sources.propTypes = {
  sources: arrayOf(SourceType).isRequired,
  linkTitleMode: oneOf(LinkTitleModes),
  toggle: func.isRequired,
  opened: bool,
  onSourceNavigate: func.isRequired
}

export default Sources
