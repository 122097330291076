import React from "react"
import { string } from "prop-types"

import Icon from "src/styles/components/Icon"

import { Container, Title, Text, TitleContainer } from "./styles"

const InformationSection = ({ title, text }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        <Icon className="pl-1" size="small" type="question-circled-hollow" />
      </TitleContainer>
      <Text>{text}</Text>
    </Container>
  )
}

InformationSection.propTypes = {
  title: string.isRequired,
  text: string.isRequired
}

export default InformationSection
