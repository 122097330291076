import React from "react"
import { string } from "prop-types"

import { Container } from "./styles"
import AlertIcon from "./AlertIcon"

const ItemStaleAlert = ({ text }) => {
  return (
    <Container>
      <AlertIcon variant="left-to-text" />
      {text}
    </Container>
  )
}

ItemStaleAlert.propTypes = {
  text: string.isRequired
}

export default ItemStaleAlert
