import React from "react"
import { func } from "prop-types"

import { DEFAULT_ACCOUNT_FILTER } from "src/resources/accounts/constants"
import { DEFAULT_TAG_FILTER } from "src/resources/tags/constants"

import PublicationStatusFilter from "./PublicationStatusFilter"
import AccountFilter from "./AccountFilter"
import TagFilter from "./TagFilter"
import YearFilter from "./YearFilter"
import useFiltersQuery from "./useFiltersQuery/useFiltersQuery"
import { filterType, filterConstraintsType } from "./constants"

const Filters = ({ filters, setFilters, constraints }) => {
  const { accountOptions, tagOptions, yearOptions } = useFiltersQuery({
    filters,
    constraints
  })

  const accountTagOptions =
    filters.accountFilter.value === DEFAULT_ACCOUNT_FILTER.value
      ? tagOptions
      : tagOptions.filter(({ accountId }) => accountId === filters.accountFilter.value)

  const setSelectedPublicationStatus = (publicationStatusOption) =>
    setFilters({
      ...filters,
      publicationStatusFilter: publicationStatusOption
    })

  const setSelectedAccount = (accountOption) =>
    setFilters({
      ...filters,
      accountFilter: accountOption,
      tagFilter: DEFAULT_TAG_FILTER
    })

  const setSelectedTag = (tagOption) => {
    const accountOption = accountOptions.find(
      ({ value }) => tagOption.accountId === value
    )

    setFilters({
      ...filters,
      accountFilter: accountOption || DEFAULT_ACCOUNT_FILTER,
      tagFilter: tagOption
    })
  }

  const setSelectedYear = (yearOption) => {
    setFilters({ ...filters, yearFilter: yearOption })
  }

  const showAccountFilter = !constraints?.accountId

  return (
    <>
      <PublicationStatusFilter
        selectedOption={filters.publicationStatusFilter}
        setSelectedOption={setSelectedPublicationStatus}
      />
      {showAccountFilter && (
        <AccountFilter
          selectedOption={filters.accountFilter}
          setSelectedOption={setSelectedAccount}
          options={accountOptions}
        />
      )}
      <TagFilter
        selectedOption={filters.tagFilter}
        setSelectedOption={setSelectedTag}
        options={accountTagOptions}
      />
      <YearFilter
        selectedOption={filters.yearFilter}
        setSelectedOption={setSelectedYear}
        options={yearOptions}
      />
    </>
  )
}

Filters.propTypes = {
  filters: filterType.isRequired,
  setFilters: func.isRequired,
  constraints: filterConstraintsType
}

export default Filters
