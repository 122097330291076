import React, { useEffect, useRef, useState } from "react"
import { cx } from "@linaria/core"
import { arrayOf, bool, func } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import { FocusableItem } from "../propTypes"
import useIsSidebarScrolled from "../useIsSidebarScrolled"

import {
  AreaOfFocusContainer,
  Label,
  DropdownButton,
  DropdownIcon,
  DropdownTitle,
  FocusableItemIcon,
  DropdownTitleLabel
} from "./styles"
import DropdownMenu from "./DropdownMenu"
import useSuggestions from "./useSuggestions"
import useSearch from "./useSearch"

const AreaOfFocus = ({
  focusedItem,
  focusedItemLoading,
  recentItems,
  recentItemsLoading,
  onSelect,
  disabled = false
}) => {
  const { isOpened, toggle, close } = useIsOpened()
  const [searchQuery, setSearchQuery] = useState("")
  const clearSearchQuery = () => setSearchQuery("")

  const { suggestions, loading: suggestionsLoading } = useSuggestions(focusedItem)
  const { searchResults, loading: searchResultsLoading } = useSearch(searchQuery)
  const isScrolled = useIsSidebarScrolled()

  const dropdownMenuRef = useRef()
  // Close dropdown menu when click outside of the menu happens
  useEffect(() => {
    const handler = (e) => {
      if (!dropdownMenuRef.current) return
      if (dropdownMenuRef.current.contains(e.target)) return
      if (!isOpened) return

      clearSearchQuery()
      close()
    }

    document.addEventListener("click", handler)

    return () => document.removeEventListener("click", handler)
  }, [!!dropdownMenuRef.current, isOpened])

  const handleSelect = (item) => {
    clearSearchQuery()
    close()
    onSelect(item)
  }

  const handleDropdownToggle = () => {
    // Don't open menu if data is not loaded yet
    if (disabled || focusedItemLoading) return

    clearSearchQuery()
    toggle()
  }

  return (
    <AreaOfFocusContainer className={isScrolled ? "hidden" : ""}>
      <Label htmlFor="area-of-focus-dropdown-button">Area of Focus</Label>

      <DropdownButton
        id="area-of-focus-dropdown-button"
        onClick={handleDropdownToggle}
        className={cx(disabled && "disabled")}
        disabled={disabled}
      >
        <DropdownTitle>
          {focusedItemLoading && "Loading..."}

          {!focusedItemLoading && focusedItem && (
            <>
              <FocusableItemIcon itemType={focusedItem.type} />
              <DropdownTitleLabel>{focusedItem.title}</DropdownTitleLabel>
            </>
          )}
        </DropdownTitle>
        <DropdownIcon type="dropdown-arrow" className={cx(isOpened && "opened")} />
      </DropdownButton>

      {isOpened && (
        <DropdownMenu
          ref={dropdownMenuRef}
          recentItems={recentItems}
          suggestions={suggestions}
          searchResults={searchResults}
          sectionsLoading={recentItemsLoading || suggestionsLoading}
          searchResultsLoading={searchResultsLoading}
          onSelect={handleSelect}
          onSearchQueryChange={setSearchQuery}
        />
      )}
    </AreaOfFocusContainer>
  )
}

AreaOfFocus.propTypes = {
  focusedItem: FocusableItem,
  focusedItemLoading: bool,
  recentItems: arrayOf(FocusableItem).isRequired,
  recentItemsLoading: bool,
  onSelect: func.isRequired,
  disabled: bool
}

export default AreaOfFocus
