import React from "react"
import { arrayOf, bool, func } from "prop-types"

import FileIcon from "src/styles/components/FileIcon"
import { splitFilename } from "src/helpers/string"
import useDocumentPreview from "src/hooks/useDocumentPreview"

import { FileCard, FileDetails, FileName, LinkButton } from "./styles"
import { Document } from "./propTypes"

const DocumentPreviewLink = ({
  document,
  collection = [],
  previewable = true,
  onClick
}) => {
  const { open } = useDocumentPreview()

  const { name, extension } = splitFilename(document.filename)

  const handleClick = (e) => {
    e.preventDefault()

    if (onClick) {
      onClick()
    } else if (previewable) {
      open({ document, collection })
    }
  }

  return (
    <FileCard>
      <LinkButton onClick={handleClick} role="button">
        <FileDetails>
          <FileIcon extension={extension} />
          <FileName>{name}</FileName>
        </FileDetails>
      </LinkButton>
    </FileCard>
  )
}

DocumentPreviewLink.propTypes = {
  document: Document,
  collection: arrayOf(Document),
  previewable: bool,
  onClick: func
}

export default DocumentPreviewLink
