import React, { useState, useMemo } from "react"
import { number } from "prop-types"
import pluralize from "pluralize"

import useRealtimeUpdates from "src/hooks/useRealtimeUpdates"
import { CHANNELS } from "src/constants/ably"
import { ContentContainer } from "src/styles/components/ContentComponents"
import ContentLoader from "src/styles/components/ContentLoader"
import { DEFAULT_FILTERS } from "src/resources/meetingPacks/constants"
import { getYear, getPublicationStatus } from "src/resources/meetingPacks/helpers"
import { getTagId } from "src/resources/tags/helpers"
import { generateUniqId } from "src/helpers/string"
import { showFlashMessage } from "src/helpers/flash"

import ListActions from "./ListActions"
import MeetingPacks from "./MeetingPacks"
import FooterPanel from "./FooterPanel"
import ConfirmationModal from "./ConfirmationModal"
import useNotAssignedMeetingPacksQuery from "./useNotAssignedMeetingPacksQuery"
import useBulkAddUserToMeetingPacks from "./useBulkAddUserToMeetingPacks"
import { Caption } from "./styles"
import { userType } from "./constants"

const List = ({ accountId, user }) => {
  const [selectedIds, setSelectedIds] = useState([])
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [role, setRole] = useState("member")
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [processing, setProcessing] = useState(false)

  const publicationStatus = getPublicationStatus(filters.publicationStatusFilter)
  const tagId = getTagId(filters.tagFilter)
  const year = getYear(filters.yearFilter)

  const onQueryCompleted = ({ meetingPacks }) => {
    const meetingPackIds = meetingPacks.map((meetingPack) => meetingPack.id)
    setSelectedIds(selectedIds.filter((id) => meetingPackIds.includes(id)))
  }

  const { meetingPacks, loading, refetch } = useNotAssignedMeetingPacksQuery({
    accountId,
    userId: user.id,
    publicationStatus,
    tagId,
    year,
    onCompleted: onQueryCompleted
  })

  const requestId = useMemo(() => generateUniqId(), [accountId, user.id])

  useRealtimeUpdates(CHANNELS.accounts.bulkAddUserToMeetingPacks, (data) => {
    if (data.userId === user.id && data.requestId === requestId && data.success) {
      setSelectedIds([])
      setProcessing(false)

      showFlashMessage(
        "success",
        `${user.fullName} has successfully been added to ${pluralize("past meeting pack", selectedIds.length, true)}.`
      )

      refetch()
    }
  })

  const { bulkAddUserToMeetingPacks } = useBulkAddUserToMeetingPacks({
    accountId,
    meetingPackIds: selectedIds,
    role,
    requestId,
    userId: user.id
  })

  const onToggleSelectAll = () => {
    if (selectedIds.length === meetingPacks.length) {
      setSelectedIds([])
    } else {
      setSelectedIds(meetingPacks.map((meetingPack) => meetingPack.id))
    }
  }

  const onToggleSelectMeetingPack = (meetingPackId) => {
    if (selectedIds.includes(meetingPackId)) {
      setSelectedIds(selectedIds.filter((id) => id !== meetingPackId))
    } else {
      setSelectedIds([...selectedIds, meetingPackId])
    }
  }

  const onConfirm = () => {
    setShowConfirmationModal(false)
    setProcessing(true)
    showFlashMessage(
      "info",
      `Adding ${user.fullName} to ${pluralize("past meeting pack", selectedIds.length, true)}...`
    )
    bulkAddUserToMeetingPacks()
  }

  if (processing) {
    return (
      <ContentContainer>
        <ContentLoader />
      </ContentContainer>
    )
  }

  return (
    <>
      <ContentContainer>
        <Caption>
          Past meeting packs that {user.fullName} does not currently have access to
        </Caption>

        <ListActions
          hideSelectAll={loading || !meetingPacks.length}
          onSelectAllClick={onToggleSelectAll}
          filters={filters}
          setFilters={setFilters}
          constraints={{ accountId, admin: true, past: true }}
        />

        <MeetingPacks
          meetingPacks={meetingPacks}
          loading={loading}
          selectedIds={selectedIds}
          onMeetingPackClick={onToggleSelectMeetingPack}
        />
      </ContentContainer>
      <FooterPanel
        user={user}
        selectedCount={selectedIds.length}
        role={role}
        setRole={setRole}
        onBulkAddClick={() => setShowConfirmationModal(true)}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        user={user}
        selectedCount={selectedIds.length}
        role={role}
        onConfirm={onConfirm}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  )
}

List.propTypes = {
  accountId: number.isRequired,
  user: userType.isRequired
}

export default List
