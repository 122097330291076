import React, { useContext } from "react"
import { Accordion, AccordionContext } from "react-bootstrap"
import { string, number, bool, func } from "prop-types"

import Icon from "src/styles/components/Icon"
import { withPropagationStopped } from "src/helpers/events"
import {
  ItemStaleAlert,
  ItemStaleAlertIcon
} from "src/features/SupportingMaterialsVault/ItemStaleAlert"

import {
  CardTitle,
  CardTitleContainer,
  Badge,
  CardHeaderContainer,
  AlertContainer
} from "./styles"

const Header = ({
  itemName,
  documentsCount,
  eventKey,
  stale,
  staleNotificationText,
  onItemTitleClick
}) => {
  const currentEventKey = useContext(AccordionContext)
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as="div" eventKey={eventKey}>
      <CardHeaderContainer>
        <CardTitleContainer>
          <div className="d-flex align-items-center">
            <Icon
              type="dropdown-arrow"
              className={`d-block ${isCurrentEventKey && "icon-rotated"}`}
            />
            <CardTitle onClick={withPropagationStopped(onItemTitleClick)}>
              {itemName}
            </CardTitle>
          </div>
          <span>
            <Badge>{documentsCount}</Badge>documents
            {stale && <ItemStaleAlertIcon height={16} variant="right-to-text" />}
          </span>
        </CardTitleContainer>

        {stale && (
          <AlertContainer>
            <ItemStaleAlert text={staleNotificationText} />
          </AlertContainer>
        )}
      </CardHeaderContainer>
    </Accordion.Toggle>
  )
}

Header.propTypes = {
  itemName: string.isRequired,
  documentsCount: number.isRequired,
  eventKey: string.isRequired,
  stale: bool.isRequired,
  onItemTitleClick: func.isRequired,
  staleNotificationText: string
}

export default Header
