import { omit, sample } from "lodash"

import { annotationPresetsChangesStorageKey } from "src/features/meetingPacks/MeetingPack/View/ContentContainer/Content/Document/setupAnnotationPresetsSaving"
import { registerServiceWorker } from "src/features/offline/registerServiceWorker"
import { getCurrentMeetingPackId } from "src/helpers/document"
import { cacheReloadUuid, getCurrentUser } from "src/helpers/user"
import { testEnv } from "src/helpers/env"

import CacheStore from "./CacheStore"

const loaderId = "cover-spin"
const progressBarId = "cover-progress-bar"

export const loadingTexts = [
  "We're just updating your Knowa experience. This should take less than a minute.",
  "Revamping your journey in the Knowa universe. Hold tight, we'll be there in under a minute.",
  "Just a moment... We're sprinkling some extra features into your Knowa experience. Sit tight, we'll be back in a jiffy.",
  "Giving your Knowa experience a quick polish! Hang in there - we'll be done in under a minute.",
  "Fine-tuning your Knowa experience for a smoother ride. We promise it'll be quicker than making a cup of coffee."
]

export const longLoadingTexts = [
  "Our gears are grinding a bit longer than usual.<br/><br/> While we hustle, please check you have a good internet connection for a smoother experience.",
  "Looks like we're in the slow lane.<br/><br/> To help us pick up the pace, please ensure your internet connection is in the fast lane.",
  "We're caught in a bit of a web time-warp.<br/><br/> Please check you have a fast internet connection to help us warp back to our speedy service.",
  "It seems our digital spaceship is caught in a minor space-time hiccup.<br/><br/> A stable and fast internet connection could be the warp drive we need!",
  "While our tech gnomes work overtime, could you kindly ensure your internet connection is shipshape?<br/> That'll help speed things up."
]

const showLoader = (text) => {
  if (document.getElementById(loaderId)) return

  const body = document.getElementsByTagName("BODY")[0]
  const loader = document.createElement("div")
  loader.setAttribute("id", loaderId)
  loader.innerHTML = `<div id="${progressBarId}"></div><span>${text}</span>`

  body.prepend(loader)
}

export const pageWasReloadAfterPSPDFKitUpdate = "pageWasReloadAfterPSPDFKitUpdate"
export const PSPDFKitUpdateProgress = "PSPDFKitUpdateProgress"

export const pspdfkitVersionIsInvalid = () =>
  window.PSPDFKit &&
  window.PSPDFKitVersion &&
  window.PSPDFKitVersion !== window.PSPDFKit.version

export const loaderText = () => {
  const pageWasReload = localStorage.getItem(pageWasReloadAfterPSPDFKitUpdate)
  return pageWasReload ? sample(longLoadingTexts) : sample(loadingTexts)
}

export const resetAnnotationPresetsSaving = () => {
  const annotationPresetsChanges = localStorage.getItem(
    annotationPresetsChangesStorageKey
  )
  if (!annotationPresetsChanges) return

  const jsonAnnotationPresets = JSON.parse(annotationPresetsChanges)

  if (typeof jsonAnnotationPresets.note?.text === "string") {
    const updatedAnnotationPresets = {
      ...jsonAnnotationPresets,
      note: omit(jsonAnnotationPresets.note, "text")
    }

    localStorage.setItem(
      annotationPresetsChangesStorageKey,
      JSON.stringify(updatedAnnotationPresets)
    )
  }
}

export const checkForPSPDFKitUpdate = ({ pspdfkitFailedToLoad, afterReloadCallback }) => {
  resetAnnotationPresetsSaving()

  const serverIsUpdating = window.PSPDFKitServerIsUpdating
  const versionIsDifferent = pspdfkitFailedToLoad || pspdfkitVersionIsInvalid()

  const forceLoadingScreenAfterPSPDFKitUpdate = async () => {
    if (testEnv()) return
    if (!getCurrentUser()) return
    if (!getCurrentMeetingPackId()) return
    // if (!cacheReloadUuid()) return

    const cacheStore = new CacheStore()
    const clearingCacheWasPerformed = await cacheStore.getPSPDFKitUpdate({
      version: window.PSPDFKitVersion,
      reloadUuid: cacheReloadUuid()
    })

    if (!clearingCacheWasPerformed)
      return checkForPSPDFKitUpdate({
        pspdfkitFailedToLoad: true,
        afterReloadCallback: () =>
          cacheStore.savePSPDFKitUpdate({
            version: window.PSPDFKitVersion,
            reloadUuid: cacheReloadUuid()
          })
      })

    return false
  }

  if (getCurrentMeetingPackId() && (serverIsUpdating || versionIsDifferent)) {
    registerServiceWorker(true)
    if (afterReloadCallback) afterReloadCallback()

    const text = loaderText()
    const reloadPageAfter = serverIsUpdating ? 3 : 0.5 // minutes
    let progress = localStorage.getItem(PSPDFKitUpdateProgress)
      ? Number(localStorage.getItem(PSPDFKitUpdateProgress))
      : 0

    localStorage.setItem(pageWasReloadAfterPSPDFKitUpdate, "true")
    setTimeout(() => window.location.reload(), reloadPageAfter * 60 * 1000)

    showLoader(text)

    // eslint-disable-next-line new-cap
    const progressBar = new window.ldBar(`#${progressBarId}`, {
      preset: "rainbow",
      value: Math.ceil(progress)
    })

    setInterval(() => {
      progress += 3.33

      if (progress < 100) {
        localStorage.setItem(PSPDFKitUpdateProgress, progress)
        progressBar.set(Math.ceil(progress))
      }
    }, 1000)

    return true
  }

  localStorage.removeItem(pageWasReloadAfterPSPDFKitUpdate)
  localStorage.removeItem(PSPDFKitUpdateProgress)
  return forceLoadingScreenAfterPSPDFKitUpdate()
}
