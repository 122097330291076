import React from "react"
import { number } from "prop-types"

import MenuItem from "src/components/HeaderMenuItem"

const MainMenu = ({ vaultId }) => {
  if (!vaultId) return null

  const currentPath = window.location.pathname

  return (
    <MenuItem
      caption="Vault"
      highlighted={!currentPath.endsWith("/members")}
      path={`/supporting_materials/vaults/${vaultId}`}
      iconClassName="icon-eye"
    />
  )
}

MainMenu.propTypes = {
  vaultId: number
}

export default MainMenu
