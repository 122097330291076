import React from "react"
import { number, string } from "prop-types"
import { useLazyQuery } from "@apollo/client"

import { be } from "src/helpers/document"
import useIsOpened from "src/hooks/useIsOpened"

import AddNonAttendeesModal from "../AddNonAttendeesModal"

import Button from "./Button"
import nonAttendeesQuery from "./nonAttendeesQuery.gql"

const AddFromOtherMeetingsButton = ({ meetingPackId, accountName }) => {
  const { open: openModal, isOpened, close } = useIsOpened()
  const [loadNonAttendees, { data }] = useLazyQuery(nonAttendeesQuery)

  const nonAttendees = data?.nonAttendeesMeetingPack?.nonAttendees || []
  const teams = data?.nonAttendeesMeetingPack?.nonAttendingTeams || []

  const openAddAccountMembersModal = () => {
    loadNonAttendees({ variables: { meetingPackId }, fetchPolicy: "network-only" })
    be("add-members-modal").modal("hide")
    openModal()
  }

  return (
    <>
      <Button onClick={openAddAccountMembersModal}>
        Add from other {accountName} Meetings
      </Button>
      <AddNonAttendeesModal
        meetingPackId={meetingPackId}
        nonAttendees={nonAttendees}
        teams={teams}
        isOpened={isOpened}
        close={close}
      />
    </>
  )
}

AddFromOtherMeetingsButton.propTypes = {
  meetingPackId: number,
  accountName: string.isRequired
}

export default AddFromOtherMeetingsButton
