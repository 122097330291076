import React, { useEffect } from "react"

import useCurrentEntity from "src/hooks/useCurrentEntity"
import {
  getIsFullscreen,
  toggleFullscreen
} from "src/components/pspsdfkitButtons/FullscreenButton/helpers"

import useDocumentPreview from "./useDocumentPreview"
import PreviewContainer from "./PreviewContainer"
import { Container, ContentContainer } from "./styles"
import Drawer from "./Drawer"

const DocumentPreview = () => {
  const { setCurrentEntity, clearCurrentEntity } = useCurrentEntity()
  const { close, document } = useDocumentPreview()

  useEffect(() => {
    setCurrentEntity({ id: document.id, type: "supporting_materials_vault_document" })

    return () => clearCurrentEntity()
  }, [document.id])

  const handleClose = () => {
    if (getIsFullscreen()) toggleFullscreen()
    close()
  }

  return (
    <Container>
      <ContentContainer className="fluid-container view-content-container">
        <PreviewContainer
          documentId={document.id}
          name={document.filename}
          pspdfkitId={document.pspdfkitId}
          jwt={document.jwt}
          contentExtracted={document.contentExtracted}
          onClose={handleClose}
        />
      </ContentContainer>

      <Drawer document={document} />
    </Container>
  )
}

export default DocumentPreview
