import { DEFAULT_ACCOUNT_FILTER } from "src/resources/accounts/constants"
import { DEFAULT_TAG_FILTER } from "src/resources/tags/constants"

export const PUBLICATION_STATUSES = {
  published: "published",
  unpublished: "unpublished"
}

export const DEFAULT_PUBLICATION_STATUS_FILTER = {
  value: "all",
  label: "All",
  isDefault: true
}

export const PUBLICATION_STATUS_OPTIONS = [
  DEFAULT_PUBLICATION_STATUS_FILTER,
  {
    value: "published",
    label: "Published"
  },
  {
    value: "unpublished",
    label: "Unpublished"
  }
]

export const ALLOW_ATTENDEES_TO_EXPORT_MODES = {
  all: "all",
  members: "members"
}

export const TRANSCRIPT_STATUSES = {
  uploaded: "uploaded",
  inProgress: "inProgress",
  complete: "complete",
  failed: "failed"
}

export const DEFAULT_YEAR_FILTER = {
  value: "all",
  label: "All Years",
  isDefault: true
}

export const DEFAULT_FILTERS = {
  publicationStatusFilter: DEFAULT_PUBLICATION_STATUS_FILTER,
  accountFilter: DEFAULT_ACCOUNT_FILTER,
  tagFilter: DEFAULT_TAG_FILTER,
  yearFilter: DEFAULT_YEAR_FILTER
}
