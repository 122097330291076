import { NetworkStatus, useQuery } from "@apollo/client"

import useRealtimeUpdates from "src/hooks/useRealtimeUpdates"
import { CHANNELS } from "src/constants/ably"

import vaultItemsQuery from "./vaultItems.gql"

const useVaultItems = ({ vaultId, onRefetch }) => {
  const { data, loading, refetch, networkStatus } = useQuery(vaultItemsQuery, {
    variables: { vaultId },
    notifyOnNetworkStatusChange: true
  })

  const vaultItems = data?.supportingMaterialsVaultItems

  useRealtimeUpdates(CHANNELS.supportingMaterials, (publisherData) => {
    if (publisherData.vaultId === vaultId) {
      refetch().then((response) =>
        onRefetch({ vaultItems: response.data.supportingMaterialsVaultItems })
      )
    }
  })

  return {
    vaultItems,
    loading: loading && networkStatus !== NetworkStatus.refetch
  }
}

export default useVaultItems
