import { useEffect } from "react"
import { useMutation } from "@apollo/client"
import { useDispatch } from "react-redux"

import { actions, useUniversalAiSelector } from "src/features/UniversalAi/store"

import stopStreamingMutation from "./stopStreaming.gql"

const SYMBOLS_COUNT_PER_ITERATION = 5

const useStreaming = ({
  streamId,
  onChange,
  onComplete,
  channelName,
  startDelay = 0
}) => {
  const dispatch = useDispatch()
  const { streaming } = useUniversalAiSelector()

  const [stopStreamingRequest] = useMutation(stopStreamingMutation, {
    variables: { channelName }
  })

  const startDelayDisabled = startDelay <= 0

  // Store state in Redux to preserve streaming on navigation
  const {
    // "content" contains currently streamed content
    content = "",
    finished = false,
    inProgress = false,
    // "contentToStream" contains whole content to stream
    contentToStream = "",
    contentStreamFinished = false,
    streamReady = startDelayDisabled,
    currentIndex = 0,
    interrupted = false
  } = streaming[streamId] || {}

  const updateState = (state) => {
    dispatch(actions.updateStreaming({ streamId, ...state }))
  }

  const setContentStreamFinished = (newContentStreamFinished) => {
    updateState({ contentStreamFinished: newContentStreamFinished })
  }

  const hasContentToStream = contentToStream.length > 0

  const updateContent = (value, options = { stream: true }) => {
    if (!value) return

    if (options.stream) {
      updateState({ contentToStream: value })
    } else {
      updateState({
        contentToStream: value,
        content: value,
        finished: true,
        currentIndex: value.length
      })

      if (onComplete) onComplete(value)
    }
  }

  const reset = () => {
    updateState({
      finished: false,
      inProgress: false,
      contentStreamFinished: false,
      streamReady: startDelayDisabled,
      content: "",
      contentToStream: "",
      currentIndex: 0,
      interrupted: false
    })
  }

  const interrupt = () => {
    updateState({
      finished: true,
      inProgress: false,
      contentToStream: content,
      contentStreamFinished: true,
      currentIndex: 0,
      streamReady: startDelayDisabled,
      interrupted: true
    })

    stopStreamingRequest()
  }

  useEffect(() => {
    if (currentIndex === 0 && hasContentToStream) {
      updateState({ inProgress: true })
    }
  }, [currentIndex, hasContentToStream])

  useEffect(() => {
    if (interrupted) return

    if (startDelayDisabled) {
      updateState({ streamReady: true })
      return
    }

    if (!hasContentToStream) return

    // To move consumers of useStreaming from loading state
    // but we don't want to call it when we are already in the middle of streaming
    // (happens on navigation)
    if (currentIndex === 0 && onChange) onChange("")
    const timeout = setTimeout(() => updateState({ streamReady: true }), startDelay)

    return () => clearTimeout(timeout)
  }, [startDelayDisabled, startDelay, hasContentToStream, interrupted])

  useEffect(() => {
    if (!streamReady || interrupted) return

    if (!finished && contentToStream && currentIndex <= contentToStream.length) {
      const timeout = setTimeout(() => {
        const currentContent = contentToStream.slice(
          0,
          currentIndex + SYMBOLS_COUNT_PER_ITERATION
        )
        updateState({
          content: currentContent,
          currentIndex: currentIndex + SYMBOLS_COUNT_PER_ITERATION + 1
        })
        if (onChange) onChange(currentContent)
      }, 50)

      return () => clearTimeout(timeout)
    }

    if (finished === true && inProgress === true) {
      onComplete(contentToStream)
      updateState({ inProgress: false })
    }
  }, [currentIndex, contentToStream, finished, streamReady, interrupted, inProgress])

  useEffect(() => {
    if (contentStreamFinished && contentToStream === content) {
      updateState({ finished: true })
    }
  }, [contentStreamFinished, contentToStream, content])

  return {
    content,
    finished,
    inProgress,
    reset,
    interrupt,
    setContent: updateContent,
    setFinished: setContentStreamFinished
  }
}

export default useStreaming
