import { useLazyQuery } from "@apollo/client"

import useDefaultDocumentPreview from "src/hooks/useDocumentPreview"

import vaultDocumentQuery from "./vaultDocument.gql"

const useDocumentPreview = () => {
  const [fetchDocument] = useLazyQuery(vaultDocumentQuery)
  const { open: defaultOpen, close, opened, document } = useDefaultDocumentPreview()

  const open = ({ documentId }) => {
    fetchDocument({ variables: { vaultDocumentId: documentId } }).then((response) => {
      if (response.error) throw response.error

      if (response.data?.supportingMaterialsVaultDocument)
        defaultOpen({ document: response.data?.supportingMaterialsVaultDocument })
    })
  }

  return { open, close, opened, document }
}

export default useDocumentPreview
