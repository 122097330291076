import React from "react"
import { arrayOf, bool, number, shape, string } from "prop-types"

import ContentLoader from "src/styles/components/ContentLoader"
import EmptyContent from "src/styles/components/EmptyContent"
import useItemDrawer from "src/features/SupportingMaterialsVault/useItemDrawer"
import ItemDrawer from "src/features/SupportingMaterialsVault/ItemDrawer"

import { ScrollContainer, Container, ItemsListContainer } from "./styles"
import List from "./List"

const ItemsList = ({ vaultItems, loading }) => {
  const { opened } = useItemDrawer()

  return (
    <>
      <Container className="fluid-container">
        <ScrollContainer>
          <ItemsListContainer>
            {loading && <ContentLoader />}
            {!loading && !vaultItems?.length && (
              <EmptyContent>No items in the Vault</EmptyContent>
            )}
            {!loading && vaultItems?.length && <List vaultItems={vaultItems} />}
          </ItemsListContainer>
        </ScrollContainer>
      </Container>

      {opened && <ItemDrawer />}
    </>
  )
}

ItemsList.propTypes = {
  vaultItems: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      stale: bool.isRequired,
      vaultDocuments: arrayOf(
        shape({
          id: number.isRequired,
          filename: string.isRequired,
          pspdfkitId: string.isRequired
        })
      )
    }).isRequired
  ),
  loading: bool.isRequired
}

export default ItemsList
