import React from "react"

import { StyledReference, ReferenceContainer } from "./styles"

const SourceReference = ({ children }) => {
  return (
    <ReferenceContainer>
      <StyledReference>{children}</StyledReference>
    </ReferenceContainer>
  )
}

export default SourceReference
