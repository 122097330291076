import React from "react"
import { string } from "prop-types"

const Header = ({ vaultName }) => {
  return (
    <div className="subheader-nav subheader-nav-grid groups-list-nav">
      <div className="subheader-title">
        <h5>{vaultName}</h5>
      </div>
    </div>
  )
}

Header.propTypes = {
  vaultName: string.isRequired
}

export default Header
