import { useEffect } from "react"

import useDocumentPreview from "src/hooks/useDocumentPreview"
import { DISCUSSIONS_NEW_ATTACHMENT_PREVIEW } from "src/constants/events"

const DocumentPreviewForNewAttachment = () => {
  const { open } = useDocumentPreview()

  const openDocumentPreview = (event) => open(event.detail)

  useEffect(() => {
    window.addEventListener(DISCUSSIONS_NEW_ATTACHMENT_PREVIEW, openDocumentPreview)

    return () => {
      window.removeEventListener(DISCUSSIONS_NEW_ATTACHMENT_PREVIEW, openDocumentPreview)
    }
  }, [])

  return null
}

export default DocumentPreviewForNewAttachment
