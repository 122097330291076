import React from "react"
import { func } from "prop-types"

import SearchableSelect from "src/components/SearchableSelect"
import { optionType } from "src/constants/propTypes"
import {
  DEFAULT_PUBLICATION_STATUS_FILTER,
  PUBLICATION_STATUS_OPTIONS
} from "src/resources/meetingPacks/constants"

const PublicationStatusFilter = ({ selectedOption, setSelectedOption }) => {
  return (
    <SearchableSelect
      prefix="Status"
      options={PUBLICATION_STATUS_OPTIONS}
      selected={selectedOption || DEFAULT_PUBLICATION_STATUS_FILTER}
      setSelected={setSelectedOption}
      data-testid="publication-status-filter"
    />
  )
}

PublicationStatusFilter.propTypes = {
  selectedOption: optionType,
  setSelectedOption: func.isRequired
}

export default PublicationStatusFilter
