import React from "react"
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types"

import { DocumentFieldTitle, DocumentFieldValue } from "./styles"

const Fields = ({ fieldValues }) => {
  return (
    <>
      {fieldValues.map((fieldValue) => (
        <div key={fieldValue.name}>
          <DocumentFieldTitle>{fieldValue.name}</DocumentFieldTitle>
          <DocumentFieldValue>
            {fieldValue.complete ? fieldValue.value || "-" : "Extracting..."}
          </DocumentFieldValue>
        </div>
      ))}
    </>
  )
}

Fields.propTypes = {
  fieldValues: arrayOf(
    shape({
      name: string.isRequired,
      value: oneOfType([string, number]),
      complete: bool.isRequired
    })
  ).isRequired
}

export default Fields
