import { useQuery } from "@apollo/client"

import meetingPacksQuery from "./notAssignedMeetingPacksQuery.gql"

const useNotAssignedMeetingPacksQuery = ({
  publicationStatus,
  accountId,
  userId,
  tagId,
  year,
  onCompleted
}) => {
  const onQueryCompleted = (data) => {
    onCompleted({ meetingPacks: data?.notAssignedMeetingPacks })
  }

  const { data, loading, refetch } = useQuery(meetingPacksQuery, {
    variables: { publicationStatus, accountId, userId, tagId, year },
    onCompleted: onQueryCompleted
  })

  const meetingPacks = data?.notAssignedMeetingPacks

  return { meetingPacks, loading, refetch }
}

export default useNotAssignedMeetingPacksQuery
