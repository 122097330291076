import classNames from "classnames"

import setActiveSidebarMode from "../setActiveSidebarMode"

// eslint-disable-next-line
import thumbnailsIcon from "./thumbnailsIcon.svg?raw"

const tinyToolbarWidth = 750

const shouldHideThumbnailsButton = (instance) => {
  const toolbar = instance.contentDocument.querySelector(".PSPDFKit-Toolbar")

  return toolbar && toolbar.offsetWidth < tinyToolbarWidth
}

export default (instance) => {
  window.addEventListener("resize", () => {
    const thumbnailsButton = instance.contentDocument.querySelector(
      ".PSPDFKit-Toolbar .thumbnails"
    )

    if (!thumbnailsButton) {
      return
    }

    if (shouldHideThumbnailsButton(instance)) {
      thumbnailsButton.classList.add("d-none")
    } else {
      thumbnailsButton.classList.remove("d-none")
    }
  })

  return {
    type: "custom",
    className: classNames(
      { "d-none": shouldHideThumbnailsButton(instance) },
      "custom-button thumbnails-button "
    ),
    id: "thumbnails-button",
    icon: thumbnailsIcon,
    title: "Thumbnails",
    onPress() {
      setActiveSidebarMode({
        instance,
        buttonClass: "thumbnails-button",
        sidebarMode: window.PSPDFKit.SidebarMode.THUMBNAILS
      })
    }
  }
}
