import React, { useEffect, useCallback } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { number, string } from "prop-types"

import ContentLoader from "src/styles/components/ContentLoader"
import ContentDivider from "src/styles/components/ContentDivider"
import EmptyContent from "src/styles/components/EmptyContent"
import useAblyChannel from "src/hooks/useAblyChannel"
import {
  getMeetingPacks,
  selectUpcomingMeetings
} from "src/resources/meetingPacks/selectors"
import { fetchMeetingPacks, fetchMeetingPack } from "src/resources/meetingPacks/thunks"
import { resetState, removeMeetingSuccess } from "src/resources/meetingPacks/slice"
import { PER_PAGE_LIMIT } from "src/constants"

import Meeting from "./Meeting"

const MeetingsList = ({ publicationStatus, accountId, tagId, year }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetState())
  }, [publicationStatus, accountId, tagId, year, dispatch])

  const {
    allIds: meetingIds,
    hasNextPage,
    isLoading
  } = useSelector(getMeetingPacks, shallowEqual)
  const [upcomingMeetings, pastMeetings] = useSelector(
    selectUpcomingMeetings,
    shallowEqual
  )
  const handleLoadMore = useCallback(() => {
    dispatch(
      fetchMeetingPacks({
        page: { offset: meetingIds.length, limit: PER_PAGE_LIMIT },
        filter: { publicationStatus, accountId, tagId, year }
      })
    )
  }, [meetingIds.length, publicationStatus, accountId, tagId, year, dispatch])

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  })

  const handleMeetingChange = useCallback(
    (message) => {
      if (message.name === "update") dispatch(fetchMeetingPack(message.data))
      if (message.name === "destroy") dispatch(removeMeetingSuccess(message.data))
    },
    [dispatch]
  )

  useAblyChannel("meeting-packs", { onMessage: handleMeetingChange })

  if (!isLoading && !hasNextPage) {
    if (!meetingIds.length) {
      return <EmptyContent>There are no meetings that match your filters.</EmptyContent>
    }
  }

  return (
    <div>
      {upcomingMeetings.map((meeting) => (
        <Meeting key={meeting.id} meeting={meeting} />
      ))}
      {!!pastMeetings.length && <ContentDivider>Past Meetings</ContentDivider>}
      {pastMeetings.map((meeting) => (
        <Meeting key={meeting.id} meeting={meeting} />
      ))}
      <div ref={infiniteRef} />
      {isLoading && <ContentLoader />}
    </div>
  )
}

MeetingsList.propTypes = {
  publicationStatus: string,
  accountId: number,
  tagId: number,
  year: number
}

export default MeetingsList
