import { exportWithNameToolbarButton } from "src/helpers/pspdfkit"
import { BREAKPOINTS } from "src/styles/sizes"

export const getContainerId = (pspdfkitId) => `signed-document-${pspdfkitId}`

export const getAllAnnotations = async (instance) =>
  (
    await Promise.all(
      Array.from(Array(instance.totalPageCount)).map((_, i) => instance.getAnnotations(i))
    )
  )
    .map((annotationsList) => annotationsList.toArray())
    .flat()

export const getFormFieldsWithAnnotations = async (instance) => {
  const [formFields, annotations] = await Promise.all([
    instance.getFormFields(),
    getAllAnnotations(instance)
  ])

  return formFields.toArray().map((formField) => {
    const fieldAnnotations = formField.annotationIds
      .toArray()
      .map((id) => annotations.find((annotation) => annotation.id === id))
      .filter(Boolean)
      .map((annotation) => annotation.toJS())
    const fieldAnnotationsIds = fieldAnnotations.map(
      (fieldAnnotation) => fieldAnnotation.id
    )

    return {
      formField: formField.toJS(),
      annotations: fieldAnnotations,
      signature: annotations
        .find((annotation) =>
          fieldAnnotationsIds.includes(annotation?.customData?.signatureFieldId)
        )
        ?.toJS()
    }
  })
}

export const getFormFieldsWithAnnotationsForUser = async (instance, userId) => {
  const formFields = await getFormFieldsWithAnnotations(instance)

  return formFields.filter((formField) =>
    formField.annotations.find(
      (annotation) => annotation?.customData?.assignedUserId === userId
    )
  )
}

export const getSignatureAnnotations = async (instance) => {
  const formFields = await instance.getFormFields()
  const annotations = await getAllAnnotations(instance)

  const signatureFields = formFields.filter(
    (field) => field instanceof window.PSPDFKit.FormFields.SignatureFormField
  )

  const signatureFieldsNames = signatureFields.map((field) => field.name)
  return annotations.filter((annotation) =>
    signatureFieldsNames.includes(annotation.formFieldName)
  )
}

export const getCommonToolbarItems = ({ instance, documentName } = {}) =>
  [
    { type: "sidebar-thumbnails" },
    { type: "sidebar-document-outline" },
    { type: "sidebar-annotations" },
    { type: "sidebar-bookmarks" },
    { type: "pager" },
    { type: "pan", mediaQueries: [`(min-width: ${BREAKPOINTS.xSmall})`] },
    { type: "zoom-out" },
    { type: "zoom-in" },
    { type: "zoom-mode" },
    { type: "spacer" },
    instance ? exportWithNameToolbarButton({ instance, documentName }) : null
  ].filter(Boolean)
