import apiClient from "src/app/apiClient"

export const uploadDocument = ({ file, vaultItemId }) => {
  const data = new FormData()
  data.append("file", file, file.name)
  data.append("vault_item_id", vaultItemId)

  return apiClient.postForm("/api/supporting_materials/vault_documents", data)
}

export const downloadDocumentUrl = ({ documentId }) =>
  `/api/supporting_materials/vault_documents/${documentId}/download`
