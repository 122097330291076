import React, { useState } from "react"

import CloseButton from "src/components/CloseButton"
import useItemDrawer from "src/features/SupportingMaterialsVault/useItemDrawer"
import { Container, Title } from "src/features/SupportingMaterialsVault/drawerStyles"

import { HeaderContainer, Header, Tabs, Tab, Content } from "./styles"
import CurrentTab from "./CurrentTab"
import HistoricalTab from "./HistoricalTab"

const TABS = [
  {
    tabId: 1,
    title: "Current",
    renderComponent: () => <CurrentTab key="1" />
  },
  { tabId: 2, title: "Historical", renderComponent: () => <HistoricalTab key="2" /> }
]

const ItemDrawer = () => {
  const { item, close } = useItemDrawer()
  const [activeTab, setActiveTab] = useState(TABS[0].tabId)

  return (
    <Container data-testid="smv-item-sidebar">
      <HeaderContainer>
        <Header>
          <Title>{item.name}</Title>
          <CloseButton onClick={close} />
        </Header>

        <Tabs>
          {TABS.map(({ tabId, title }) => (
            <Tab
              key={tabId}
              className={activeTab === tabId && "current"}
              onClick={() => setActiveTab(tabId)}
            >
              {title}
            </Tab>
          ))}
        </Tabs>
      </HeaderContainer>

      <Content>
        {TABS.map(
          ({ tabId, renderComponent }) => activeTab === tabId && renderComponent()
        )}
      </Content>
    </Container>
  )
}

export default ItemDrawer
