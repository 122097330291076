import {
  DEFAULT_PUBLICATION_STATUS_FILTER,
  DEFAULT_YEAR_FILTER
} from "src/resources/meetingPacks/constants"

export const getYearOption = (year) => {
  if (!year) return

  return {
    value: year,
    label: year.toString()
  }
}

export const getYear = (option) => {
  if (option.value === DEFAULT_YEAR_FILTER.value) return

  return option.value
}

export const getPublicationStatus = (option) => {
  if (option?.value === DEFAULT_PUBLICATION_STATUS_FILTER.value) return

  return option?.value
}
