import React from "react"
import { arrayOf, bool, number, shape, string } from "prop-types"
import pluralize from "pluralize"
import { pick } from "lodash"

import useItemDrawer from "src/features/SupportingMaterialsVault/useItemDrawer"

import ItemCard from "./ItemCard"

const List = ({ vaultItems }) => {
  const { open: openItemDrawer } = useItemDrawer()
  const vaultItemOptions = vaultItems.map((vaultItem) => pick(vaultItem, ["id", "name"]))

  return (
    <>
      <div className="d-flex">
        <div className="small">
          {vaultItems?.length}{" "}
          <span className="text-muted">
            {pluralize("item", vaultItems?.length, false)}
          </span>
        </div>
      </div>

      <div className="mt-3">
        {vaultItems.map((vaultItem) => (
          <ItemCard
            key={vaultItem.id}
            vaultItem={vaultItem}
            vaultItemOptions={vaultItemOptions}
            openItemDrawer={() => {
              openItemDrawer({ itemId: vaultItem.id, items: vaultItems })
            }}
          />
        ))}
      </div>
    </>
  )
}

List.propTypes = {
  vaultItems: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      stale: bool.isRequired,
      vaultDocuments: arrayOf(
        shape({
          id: number.isRequired,
          filename: string.isRequired,
          pspdfkitId: string.isRequired
        })
      )
    }).isRequired
  )
}

export default List
