import React, { useEffect } from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"

import {
  getContainerId,
  getCommonToolbarItems
} from "src/features/signedDocuments/helpers"

import {
  areSignatureFieldsHaveUsers,
  buildUserSelect,
  createAnnotationRenderer,
  getAnnotationFormField,
  getAssignedUsersIds,
  hideAdvancedSection,
  removeFieldNameInput,
  selectUserInUserSelect
} from "./helpers"

const DocumentFormBuilder = ({
  isOpened,
  id,
  jwt,
  documentName,
  groupMembers,
  setFieldsToSignHaveUsers,
  setAssignedUsersIds
}) => {
  const container = getContainerId(id)

  useEffect(() => {
    if (isOpened) {
      window.PSPDFKit.load({
        licenseKey: window.pspdfkitKey,
        serverUrl: window.pspdfkitServerUrl,
        styleSheets: [window.pspdfkitESignatureStylesheetPath],
        editableAnnotationTypes: [
          window.PSPDFKit.Annotations.WidgetAnnotation,
          window.PSPDFKit.Annotations.TextAnnotation
        ],
        enableClipboardActions: false,
        enableHistory: true,
        container: `#${container}`,
        documentId: id,
        instant: true,
        authPayload: { jwt },
        customRenderers: {
          Annotation: createAnnotationRenderer(groupMembers)
        },
        toolbarItems: [
          ...getCommonToolbarItems({ documentName }),
          { type: "form-creator" },
          { type: "text" }
        ],
        initialViewState: new window.PSPDFKit.ViewState({
          interactionMode: window.PSPDFKit.InteractionMode.FORM_CREATOR
        }),
        theme: window.PSPDFKit.Theme.LIGHT
      }).then((instance) => {
        const blueColor = new window.PSPDFKit.Color({ r: 239, g: 241, b: 255 })

        // Event listener to check whether the clicked annotation is a Signature Widget
        instance.addEventListener("annotations.create", (loadedAnnotations) => {
          loadedAnnotations.forEach((annotation) => {
            if (annotation.formFieldName?.includes("SIGNATURE_WIDGET")) {
              const box = new window.PSPDFKit.Geometry.Rect({
                left: annotation.boundingBox.left,
                top: annotation.boundingBox.top,
                width: annotation.boundingBox.width,
                height: 60
              })
              instance.update(
                annotation.set("boundingBox", box).set("backgroundColor", blueColor)
              )
            }
          })
        })

        instance.addEventListener("annotations.focus", async (event) => {
          const { annotation } = event
          const assignedUserId = annotation.customData?.assignedUserId

          const formField = await getAnnotationFormField(annotation, instance)

          if (!(formField instanceof window.PSPDFKit.FormFields.FormField)) {
            return
          }

          instance.setSelectedAnnotations(annotation.id)

          hideAdvancedSection(instance.contentDocument)
          removeFieldNameInput(instance.contentDocument)

          const assignedUserField = instance.contentDocument.querySelector(
            ".PSPDFKit-Form-Assigned-User select"
          )
          if (assignedUserField) {
            assignedUserField.setAttribute("data-annotation-id", annotation.id)
            selectUserInUserSelect({ select: assignedUserField, userId: assignedUserId })

            return
          }

          const expandoControl = instance.contentDocument.querySelector(
            ".PSPDFKit-Expando-Control"
          )

          if (!expandoControl) {
            return
          }

          expandoControl.insertAdjacentHTML(
            "beforebegin",
            buildUserSelect({
              assignedUserId,
              annotationId: annotation.id,
              users: groupMembers
            })
          )

          const { pageIndex } = annotation

          instance.contentDocument
            .querySelector("#assignedUser")
            .addEventListener("change", async (e) => {
              // We have to reload annotation because annotation in variable "annotation"
              // can be out of date when user is selected
              const annotationToUpdate = (await instance.getAnnotations(pageIndex)).find(
                (ann) => ann.id === e.target.dataset.annotationId
              )

              if (!annotationToUpdate) {
                return
              }

              instance.update(
                annotationToUpdate.set("customData", {
                  ...(annotationToUpdate.get("customData") || {}),
                  assignedUserId: Number(e.target.value)
                })
              )

              instance.setSelectedAnnotations(null)
            })
        })

        instance.addEventListener("annotations.update", async () => {
          await areSignatureFieldsHaveUsers(instance).then(setFieldsToSignHaveUsers)
          await getAssignedUsersIds(instance).then(setAssignedUsersIds)
        })
      })
    }

    return () => {
      try {
        window.PSPDFKit.unload(`#${container}`)
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [id, container, isOpened])

  return <div id={container} className="document-form-builder h-100" />
}

DocumentFormBuilder.propTypes = {
  isOpened: bool.isRequired,
  id: string.isRequired,
  jwt: string.isRequired,
  documentName: string.isRequired,
  setFieldsToSignHaveUsers: func.isRequired,
  setAssignedUsersIds: func.isRequired,
  groupMembers: arrayOf(
    shape({
      id: number.isRequired,
      fullName: string.isRequired
    })
  )
}

export default DocumentFormBuilder
