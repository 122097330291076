export const Modes = {
  Sidebar: "sidebar",
  Modal: "modal"
}

export const Commands = {
  SummariseAsText: "Summarise as text",
  GenerateExtensiveMinutes: "Extensive Minutes",
  GenerateShortMinutes: "Concise Minutes",
  Query: "Query"
}

export const SIDEBAR_OPENED_EVENT = "universal-ai:sidebar-opened"
export const SIDEBAR_CLOSED_EVENT = "universal-ai:sidebar-closed"

export const SIDEBAR_FULLSCREEN_BREAKPOINT = 768
export const SIDEBAR_OVERLAY_BREAKPOINT = 1388

export const FocusableItemType = {
  AttachedDocument: "attached_document",
  ContentCloudFile: "content_cloud_file",
  Message: "message",
  Discussion: "discussion",
  Group: "group",
  PrivateScheme: "private_scheme",
  JointScheme: "joint_scheme",
  Account: "account",
  MeetingPack: "meeting_packs_meeting_pack",
  AgendaItem: "meeting_packs_agenda_item",
  Transcript: "meeting_packs_transcript",
  AssignedTranscriptAgendaItem: "meeting_packs_assigned_transcript_agenda_item",
  AgendaItemDocument: "meeting_packs_agenda_item_document",
  AppsContent: "apps_content",
  SupportingMaterialsVault: "supporting_materials_vault",
  SupportingMaterialsVaultDocument: "supporting_materials_vault_document"
}

export const FocusableItemTypes = Object.values(FocusableItemType)

export const FAIL_MESSAGE =
  "Apologies, Knowa is momentarily busy on a date with its AI companions. Please try again shortly."

export const FAIL_AUTHENTICATION_MESSAGE =
  "You are not authorized. Please try to reload the page."

export const STREAMING_START_DELAY = 1000 // 1s

export const Status = {
  Pending: "pending",
  Loading: "loading",
  InProgress: "inProgress",
  Finished: "finished"
}

export const SourceClickTarget = {
  PreviewTitle: "preview_title",
  SourcesList: "sources_list",
  Answer: "answer"
}
