import React from "react"
import { string } from "prop-types"
import classnames from "classnames"

import alertIcon from "images/alert-circle.svg"

import { Icon } from "./styles"

const AlertIcon = ({ variant, className, ...props }) => {
  const classes = variant ? classnames(className, `variant-${variant}`) : className

  return <Icon src={alertIcon} className={classes} {...props} />
}

AlertIcon.propTypes = {
  variant: string
}

export default AlertIcon
