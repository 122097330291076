import { useQuery } from "@apollo/client"
import { sortBy } from "lodash"

import { MEMBERSHIP_TYPES_FOR } from "src/features/meetingPacks/MeetingPack/constants"

import meetingPackMembershipsQuery from "./meetingPackMemberships.gql"

export default function useMemberships({ meetingPackId }) {
  const { data } = useQuery(meetingPackMembershipsQuery, { variables: { meetingPackId } })

  return sortBy(data?.meetingPackMemberships || [], ({ membershipType }) =>
    MEMBERSHIP_TYPES_FOR.meetingPackExport.indexOf(membershipType)
  )
}
