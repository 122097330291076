import React from "react"
import { string, func, number, bool } from "prop-types"

import CloseButton from "src/components/CloseButton"

import {
  Container,
  DocumentViewerContainer,
  Header,
  Title,
  TitleContainer
} from "./styles"
import DocumentViewer from "./DocumentViewer"

const PreviewContainer = ({
  documentId,
  name,
  pspdfkitId,
  jwt,
  contentExtracted,
  onClose
}) => {
  return (
    <Container data-behavior="document-preview">
      <Header>
        <TitleContainer>
          <Title>{name}</Title>
        </TitleContainer>
        <CloseButton onClick={onClose} />
      </Header>
      <DocumentViewerContainer>
        <DocumentViewer
          documentId={documentId}
          pspdfkitId={pspdfkitId}
          jwt={jwt}
          contentExtracted={contentExtracted}
        />
      </DocumentViewerContainer>
    </Container>
  )
}

PreviewContainer.propTypes = {
  documentId: number.isRequired,
  name: string.isRequired,
  pspdfkitId: string.isRequired,
  jwt: string.isRequired,
  contentExtracted: bool.isRequired,
  onClose: func.isRequired
}

export default PreviewContainer
